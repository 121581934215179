export class CsvUser {
  firstName: string;

  lastName: string;

  email: string;

  password: string;

  updatePassword: boolean;

  sendNotification: boolean;

  index?: number;

  deleted?: boolean;

  constructor(
    props: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      updatePassword?: boolean;
      sendNotification?: boolean;
      index?: number;
      deleted?: boolean;
    },
    noOptional: boolean,
  ) {
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.email = props.email;
    this.password = props.password;

    if (!noOptional && typeof props.updatePassword !== 'undefined') {
      this.updatePassword = props.updatePassword;
    } else {
      this.updatePassword = false;
    }

    if (!noOptional && typeof props.sendNotification !== 'undefined') {
      this.sendNotification = props.sendNotification;
    } else {
      this.sendNotification = true;
    }

    if (!noOptional && typeof props.index !== 'undefined') {
      this.index = props.index;
    }

    if (!noOptional && typeof props.deleted !== 'undefined') {
      this.deleted = props.deleted;
    }
  }
}
