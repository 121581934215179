<template>
  <v-container id="conferences-rooms">
    <h2 class="conferences-rooms__title">
      {{ event.name }} - {{ $t('conferenceRoom.list.title') }}
    </h2>

    <div class="conferences-rooms__actions">
      <v-btn
        large
        depressed
        :disabled="!canCreate"
        class="actions__create"
        color="primary"
        @click="openDialog('edit')"
        data-test-id="btn-create"
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <base-list
      backgroundColor="var(--v-adminBackground-base)"
      class="mt-4"
      :headers="headers"
      :items="conferenceRooms"
      :search="search"
      :loading="conferenceRoomsAreLoading"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.public`]="{ item }">
        <v-icon class="icon icon__restricted" v-if="!item.public"> mdi-lock-outline </v-icon>
        <v-icon class="icon" v-else>mdi-lock-open-outline</v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="currentLoggedInUser"
          @edit-item="openDialog('edit', item)"
          @delete-item="openDialog('delete', item)"
          :can-edit="canUpdate"
          :can-delete="canDelete"
          :is-disabled="hasConference(item)"
          :disabled-tooltip="$t('conferenceRoom.delete.hasContent')"
        />
      </template>
    </base-list>

    <v-dialog
      content-class="v-dialog--overlay"
      v-if="dialog.edit"
      v-model="dialog.edit"
      width="800"
      @click:outside="closeDialog('edit')"
      data-test-id="edit-dialog"
    >
      <v-card>
        <v-card-title>{{ event.name }} - {{ $t('conferenceRoom.editModal.title') }}</v-card-title>

        <v-card-text>
          <edit-room-admin
            :eventId="eventId"
            :conferenceRoomId="selectedConfRoomId"
            @closeDialog="closeDialog('edit')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <dialog-confirmation
      :v-if="dialog.delete"
      :visible="dialog.delete"
      :title="$t('conferenceRoom.delete.deleteConfirmationDialog.title')"
      :content="$t('conferenceRoom.delete.deleteConfirmationDialog.content')"
      @cancel="closeDialog('delete')"
      @confirm="confirmationDialogConfirm"
      data-test-id="delete-dialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DialogConfirmation from '@/views/DialogConfirmation.vue';
import EditRoomAdmin from '@/views/admin/conference-rooms/edit/EditRoomAdmin.vue';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  CLEAR_CONFERENCE_ROOM,
  GET_CONFERENCES_FOR_EVENT,
  GET_CONFERENCE_ROOMS,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADMIN_CONFERENCE_MODULE,
  DELETE_CONFERENCE_ROOM,
} from '@/stores/umanize-admin/actions/conference/admin-conference.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'ConferenceRoomsAdmin',
  components: {
    AdminTableActions,
    DialogConfirmation,
    BaseList,
    EditRoomAdmin,
    AppSearch,
  },
  data: () => ({
    search: '',
    seletedConfRoom: null,
    selectedConfRoomId: null,
    dialog: {
      edit: false,
      delete: false,
    },
  }),
  computed: {
    ...mapGetters(ADMIN_CONFERENCE_MODULE, [
      'conferences',
      'conferenceRooms',
      'conferenceRoomsAreLoading',
    ]),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    headers() {
      return [
        {
          text: this.$t('conference.editModal.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('conference.editModal.public'),
          value: 'public',
          sortable: false,
          align: 'center',
          width: '100px',
        },
        {
          value: 'actions',
          align: 'center',
          width: '100px',
        },
      ];
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canCreate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canDelete'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    eventId() {
      return this.$route.params?.eventId;
    },
  },
  methods: {
    ...mapActions(ADMIN_CONFERENCE_MODULE, [
      GET_CONFERENCE_ROOMS,
      GET_CONFERENCES_FOR_EVENT,
      CLEAR_CONFERENCE_ROOM,
      DELETE_CONFERENCE_ROOM,
    ]),
    onSearchChange(search) {
      this.search = search;
    },
    openDialog(name, item) {
      this.selectedConfRoom = item;
      this.selectedConfRoomId = item?.id;
      this.dialog[name] = true;
    },
    closeDialog(name) {
      this.selectedConfRoom = null;
      this.selectedConfRoomId = null;
      this.dialog[name] = false;
      this.loadConferenceRooms();
    },
    async confirmationDialogConfirm() {
      await this.deleteConferenceRoom(this.conferenceRoomToDelete);
      this.closeDialog('delete');
    },
    async deleteConferenceRoom() {
      await this[DELETE_CONFERENCE_ROOM]({
        eventId: this.eventId,
        conferenceRoomId: this.selectedConfRoom.id,
      });
    },
    hasConference(conferenceRoom) {
      return this.conferences.filter((conf) => conf.roomId === conferenceRoom.id).length > 0;
    },
    loadConferenceRooms() {
      this[GET_CONFERENCE_ROOMS](this.eventId);
    },
  },
  mounted() {
    this[GET_CONFERENCES_FOR_EVENT](this.eventId);
    this.loadConferenceRooms();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

#conferences-rooms {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.conferences-rooms {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
