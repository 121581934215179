<template>
  <v-container class="edit-content-library">
    <v-form v-model="isValid" class="form" :disabled="!canUpdatePartial">
      <h1 class="edit-content-library__title">
        {{ event.name }} -
        {{ isEditing ? $t('contentLibraries.edit.title') : $t('contentLibraries.add.title') }}
      </h1>

      <v-container class="edit-content-library__content">
        <v-tabs v-if="isEditing" v-model="contentLibraryActiveTab">
          <v-tab @click="handleActiveTab('library')" href="#library">
            {{ $t('contentLibraries.edit.library') }}
          </v-tab>

          <v-tab @click="handleActiveTab('content')" href="#content">
            {{ $t('contentLibraries.edit.content') }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="contentLibraryActiveTab">
          <v-tab-item value="library" class="library">
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="contentLibraryForm.name"
                  :rules="nameRules"
                  :label="$t('contentLibraries.editModal.name')"
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" lg="3">
                <div class="cover-img__title">
                  {{ $t('contentLibraries.edit.coverImage') }}
                </div>

                <v-img
                  class="img-container my-2"
                  height="140"
                  width="300"
                  :src="contentLibraryForm.coverImage"
                >
                  <p v-if="!contentLibraryForm.coverImage">
                    {{ $t('event.edit.noCover') }}
                  </p>
                </v-img>

                <div v-if="canUpdatePartial" class="cover__actions">
                  <v-btn class="mr-3" color="primary" @click="openPictureDialog('coverImage')">
                    {{ $t('globals.upload') }}
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>

                  <v-btn color="error" @click="removePicture()">
                    {{ $t('globals.reset') }}
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="content">
            <content-items-admin
              :event="event"
              :contentItems="contentItems"
              :contentCategory="contentCategory"
              :itemsAreLoading="itemsAreLoading"
              :user="currentLoggedInUser"
              :can-update="canUpdate"
              :can-delete="canDelete"
              @edit-item="editContentItem"
              @delete-item="confirmDeleteContentItem"
            />

            <dialog-confirmation
              :v-if="contentItemsToDelete"
              :visible="showConfirmation"
              :title="$t('contentItem.delete.deleteConfirmationDialog.title')"
              :content="$t('contentItem.delete.deleteConfirmationDialog.content')"
              @cancel="confirmationDialogCancel"
              @confirm="confirmationDialogConfirm"
            />
          </v-tab-item>

          <v-card-actions class="actions">
            <v-btn data-test-id="cancel" color="primary" outlined @click="goBackToList">
              {{ $t('globals.cancel') }}
            </v-btn>

            <v-btn
              data-test-id="add"
              :disabled="
                !isValid || !contentLibraryForm.coverImage || categoryIsSaving || !canUpdatePartial
              "
              :loading="categoryIsSaving"
              color="primary"
              @click="save"
            >
              {{ isEditing ? $t('globals.save') : $t('contentLibraries.add.add') }}
            </v-btn>
          </v-card-actions>
        </v-tabs-items>

        <upload-pictures
          :dialog="pictureDialog"
          :is-multiple="false"
          :height="pictureHeight"
          :width="pictureWidth"
          @save="uploadPicture"
          @close="closePictureDialog"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import set from 'lodash.set';
import { mapActions, mapGetters } from 'vuex';

import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import ContentItemsAdmin from '@/components/admin/content-items/ContentItemsAdmin.vue';

import {
  GET_CONTENT_CATEGORY,
  GET_CONTENT_ITEMS,
} from '@/stores/agnostic/actions/content/agnostic-content.actions';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  ADMIN_CONTENT_MODULE,
  CLEAR_CONTENT_CATEGORY,
  DELETE_CONTENT_ITEM,
  SAVE_CONTENT_CATEGORY,
} from '@/stores/umanize-admin/actions/content/admin-content.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import DialogConfirmation from '../../../DialogConfirmation.vue';

export default {
  name: 'EditContentLibraryAdmin',
  components: {
    UploadPictures,
    ContentItemsAdmin,
    DialogConfirmation,
  },
  data: () => ({
    defaultContentLibrary: {
      id: '',
      eventId: '',
      name: '',
      coverImage: '',
    },
    contentLibraryForm: {
      id: '',
      eventId: '',
      name: '',
      coverImage: '',
    },
    contentLibraryActiveTab: 'library',
    pictureDialogType: null,
    pictureDialog: false,
    pictureWidth: 800,
    pictureHeight: 450,
    isValid: false,
    isCoverImageValid: false,
    nameRules: VALIDATORS.REQUIRED.NAME,
    contentItemsToDelete: null,
    showConfirmation: false,
  }),
  computed: {
    ...mapGetters(ADMIN_CONTENT_MODULE, [
      'contentCategory',
      'categoryIsSaving',
      'contentItems',
      'itemsAreLoading',
    ]),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    isEditing() {
      return this.$route.path.includes('edit');
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.contentLibraries.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdatePartial() {
      return (
        this.canUpdate ||
        PermissionsUtil.isAuthorized(
          ['permission.contentLibraries.canUpdatePartial'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.contentLibraries.canDelete'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_CONTENT_MODULE, [
      GET_CONTENT_CATEGORY,
      SAVE_CONTENT_CATEGORY,
      CLEAR_CONTENT_CATEGORY,
      GET_CONTENT_ITEMS,
      DELETE_CONTENT_ITEM,
    ]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    initForm() {
      this.contentLibraryForm = {
        ...this.defaultContentLibrary,
        ...this.contentCategory,
        eventId: this.$route.params.eventId,
      };
    },
    handleActiveTab(tab) {
      this.$router.replace({ hash: `#${tab}` });
    },
    editContentItem(item) {
      const { eventId, contentLibraryId } = this.$route.params;
      this.$router.push({
        name: 'EditContentItemAdmin',
        params: {
          eventId,
          contentLibraryId,
          contentItemId: item.id,
        },
      });
    },
    goBackToList() {
      this.$router.back();
    },
    openPictureDialog(type) {
      this.dialogType = type;
      this.pictureDialog = true;
    },
    closePictureDialog() {
      this.dialogType = null;
      this.pictureDialog = false;
    },
    async save() {
      await this[SAVE_CONTENT_CATEGORY](this.contentLibraryForm);
      this.goBackToList();
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      set(this.contentLibraryForm, this.dialogType, this.file.url);
      this.closePictureDialog();
    },
    removePicture() {
      this.contentLibraryForm.coverImage = null;
    },
    confirmDeleteContentItem(item) {
      this.contentItemsToDelete = item;
      this.showConfirmation = true;
    },
    confirmationDialogCancel() {
      this.contentItemsToDelete = null;
      this.showConfirmation = false;
    },
    async confirmationDialogConfirm() {
      await this.deleteContentItem(this.contentItemsToDelete);
      this.contentItemsToDelete = null;
      this.showConfirmation = false;
    },
    async deleteContentItem(item) {
      await this[DELETE_CONTENT_ITEM]({
        eventId: this.$route.params.eventId,
        categoryId: this.contentCategory.id,
        itemId: item.id,
      });
    },
  },
  async mounted() {
    const { eventId, contentLibraryId } = this.$route.params;
    if (contentLibraryId) {
      await this[GET_CONTENT_CATEGORY]({
        eventId,
        categoryId: contentLibraryId,
      });
      await this[GET_CONTENT_ITEMS]({
        eventId,
        categoryId: contentLibraryId,
      });
    }

    this.contentLibraryActiveTab = this.$route.hash.replace('#', '') || 'library';
    this.initForm();
  },
  async beforeDestroy() {
    await this[CLEAR_CONTENT_CATEGORY]();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.edit-content-library {
  padding: 50px 25px;
  min-height: calc(100vh - 6rem);

  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    padding: 20px 40px;
  }
}

.cover {
  &__actions {
    display: flex;
  }
}

.cover-img {
  &__title {
    font-weight: 600;
    margin: 0;
  }
}

.img-container {
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}

.library {
  ::v-deep .v-text-field .v-label {
    font-weight: 600;
  }
}

.v-tabs-items {
  overflow: inherit;
}
</style>
