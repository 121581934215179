export class CustomUI {
  id: string;

  eventId: string;

  event3dBtnText?: string;

  event2dBtnText?: string;

  conferenceBtnText?: string;

  generalAssembliesBtnText?: string;

  discussionGroupsBtnText?: string;

  contentLibraryBtnText?: string;

  btnColor?: string;

  btnTextColor?: string;

  headerTextColor?: string;

  headerBackgroundColor?: string;

  headerSupportEmail?: string;

  headerFont?: string;

  headerLogoWeb?: string;

  footerBackgroundColor?: string;

  footerTextColor?: string;

  footerCopyright?: { fr: string; en: string };

  footerLogoContent?: { fr: string; en: string };

  footerLogoPosition?: string;
}
