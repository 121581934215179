<template>
  <v-container id="custom-ui" class="full-height">
    <v-row align="baseline">
      <v-col mb-3 cols="auto">
        <v-icon @click="goBackToEventDetails" class="cursor-pointer">mdi-arrow-left</v-icon>
      </v-col>

      <v-col cols="auto">
        <h1 class="custom-ui__title">{{ event.name }} - {{ $t('admin.custom-ui.title') }}</h1>
      </v-col>
    </v-row>

    <v-container class="custom-ui__content">
      <v-tabs v-model="tab" centered>
        <v-tab :key="'general'">{{ $t('admin.custom-ui.tabs.general') }}</v-tab>
        <v-tab :key="'header'">{{ $t('admin.custom-ui.tabs.header') }}</v-tab>
        <v-tab :key="'footer'">{{ $t('admin.custom-ui.tabs.footer') }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="'general'">
          <customUIGeneral :canUpdate="canUpdate" :canCreate="canCreate" />
        </v-tab-item>

        <v-tab-item :key="'header'">
          <customUIHeader :canUpdate="canUpdate" :canCreate="canCreate" />
        </v-tab-item>

        <v-tab-item :key="'footer'">
          <customUIFooter :canUpdate="canUpdate" :canCreate="canCreate" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import CustomUIGeneral from './custom-ui-general/CustomUIGeneral.vue';
import CustomUIFooter from './custom-ui-footer/CustomUIFooter.vue';
import CustomUIHeader from './custom-ui-header/CustomUIHeader.vue';

export default {
  components: { CustomUIFooter, CustomUIGeneral, CustomUIHeader },
  name: 'CustomUIAdmin',
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),

    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.customUi.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.customUi.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
  },
  data: () => ({
    tab: null,
  }),
  methods: {
    goBackToEventDetails() {
      this.$router.push({
        name: 'EventDetailsAdmin',
        params: { eventId: this.event.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.full-height {
  min-height: calc(100vh);
}

#custom-ui {
  background-color: var(--v-adminBackground-base);
  padding: 50px;
}

.custom-ui {
  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }
  &__content {
    @include admin-card;
    margin: 0;
    padding: 20px 40px;
  }

  .title {
    margin-bottom: 10px;
    font-weight: $semi-bold;
  }

  .v-color-picker__alpha {
    display: none;
  }
}
</style>
