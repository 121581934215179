<template>
  <v-container id="streams">
    <h2 class="streams-list__title">
      {{ $t('admin.streams.title', { eventName: event.name }) }}
    </h2>

    <div class="streams-list__actions">
      <v-btn
        large
        depressed
        class="actions__create"
        color="primary"
        @click="addStream()"
        :disabled="!canCreateStreams"
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </div>

    <base-list
      class="mt-4"
      backgroundColor="var(--v-adminBackground-base)"
      :headers="headers"
      :headerProps="{ sortIcon: null }"
      :items="streams"
      :itemsPerPage="10"
      :loading="streamsAreLoading"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ getStreamName(item) }}
      </template>

      <template v-slot:[`item.rtmp`]="{ item }">
        <div class="rtmp" @click="copyToClipboard(item)">
          {{ item.rtmp }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="currentLoggedInUser"
          @edit-item="editStream(item)"
          @delete-item="deleteStreamConfirmation(item)"
          :can-delete="canDeleteStreams && !isDeleted(item)"
        >
          <v-btn icon @click="toggleStatusConfirmation(item)" v-if="canStopStream(item)">
            <v-icon :title="$t('globals.stop')">mdi-stop-circle</v-icon>
          </v-btn>

          <v-tooltip v-if="canStartStream(item)" bottom :disabled="!isStreamDisabled(item)">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  icon
                  @click="toggleStatusConfirmation(item)"
                  :disabled="isStreamDisabled(item)"
                >
                  <v-icon :title="$t('globals.start')">mdi-play-circle</v-icon>
                </v-btn>
              </div>
            </template>

            <span v-if="isStopped(item)">{{
              $t('admin.streams.disabledToolTipStreamStopped')
            }}</span>
            <span v-else>{{ $t('admin.streams.disabledToolTipStreamOngoing') }}</span>
          </v-tooltip>
        </admin-table-actions>
      </template>
    </base-list>

    <v-dialog
      v-model="dialog.stream"
      v-if="dialog.stream"
      width="800"
      @click:outside="closeDialog('stream')"
    >
      <v-card>
        <v-card-title>
          {{ event.name }} -
          {{ $t(`admin.streams.editModal.title.${modalTitle}`) }}
        </v-card-title>

        <v-card-text>
          <edit-stream-admin
            :eventId="eventId"
            :streamId="selectedStreamId"
            @closeDialog="closeDialog('stream')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <dialog-confirmation
      :v-if="dialog.delete"
      :visible="dialog.delete"
      :title="$t('admin.streams.delete.deleteConfirmationDialog.title')"
      :content="$t('admin.streams.delete.deleteConfirmationDialog.content')"
      :is-loading="streamIsSaving"
      @cancel="closeDialog('delete')"
      @confirm="deleteDialogConfirm"
    />

    <dialog-confirmation
      :v-if="dialog.start"
      :visible="dialog.start"
      :title="$t('admin.streams.startConfirmationDialog.title')"
      :content="$t('admin.streams.startConfirmationDialog.content')"
      :is-loading="streamIsSaving"
      @cancel="closeDialog('start')"
      @confirm="startDialogConfirm"
    />

    <dialog-confirmation
      :v-if="dialog.stop"
      :visible="dialog.stop"
      :title="$t('admin.streams.stopConfirmationDialog.title')"
      :content="$t('admin.streams.stopConfirmationDialog.content')"
      :is-loading="streamIsSaving"
      @cancel="closeDialog('stop')"
      @confirm="stopDialogConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DialogConfirmation from '@/views/DialogConfirmation.vue';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import { MessageType } from '@/models/message/message-type.model';
import { StreamStatus } from '@/models/stream/stream.model';

import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';

import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { ADMIN_CONFERENCE_MODULE } from '@/stores/umanize-admin/actions/conference/admin-conference.actions';
import { ADMIN_ASSEMBLY_MODULE } from '@/stores/umanize-admin/actions/general-assembly/general-assembly.actions';

import { GET_CONFERENCES_FOR_EVENT } from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import { GET_ALL_ASSEMBLIES } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import {
  DELETE_STREAM,
  START_STREAM,
  STOP_STREAM,
  STREAM_MODULE,
} from '@/stores/umanize-admin/actions/stream/stream.actions';
import { GET_ALL_STREAMS } from '@/stores/agnostic/actions/stream/agnostic-stream.actions';

import EditStreamAdmin from './edit/EditStreamAdmin.vue';

export default {
  name: 'StreamsAdmin',
  components: {
    AdminTableActions,
    BaseList,
    DialogConfirmation,
    EditStreamAdmin,
  },
  data: () => ({
    dialog: {
      stream: false,
      delete: false,
      start: false,
      stop: false,
    },
    modalTitle: 'edit',
    selectedStream: null,
    mergedConferencesAssembliesNames: new Map(),
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    ...mapGetters(STREAM_MODULE, ['streams', 'streamsAreLoading', 'streamIsSaving']),
    ...mapGetters(ADMIN_CONFERENCE_MODULE, ['conferenceIdNameMap']),
    ...mapGetters(ADMIN_ASSEMBLY_MODULE, ['generalAssemblyIdNameMap']),
    headers() {
      return [
        {
          text: this.$t('admin.streams.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('admin.streams.headers.rtmp'),
          sortable: false,
          value: 'rtmp',
        },
        {
          value: 'actions',
          align: 'center',
          width: '150px',
        },
      ];
    },
    eventId() {
      return this.$route.params.eventId;
    },
    canEditStreams() {
      return PermissionsUtil.isAuthorized(
        ['permission.streaming.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canCreateStreams() {
      return PermissionsUtil.isAuthorized(
        ['permission.streaming.canCreate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canDeleteStreams() {
      return PermissionsUtil.isAuthorized(
        ['permission.streaming.canDelete'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    selectedStreamId() {
      return this.selectedStream?.id;
    },
  },
  methods: {
    ...mapActions(ADMIN_CONFERENCE_MODULE, [GET_CONFERENCES_FOR_EVENT]),
    ...mapActions(ADMIN_ASSEMBLY_MODULE, [GET_ALL_ASSEMBLIES]),
    ...mapActions(STREAM_MODULE, [GET_ALL_STREAMS, START_STREAM, STOP_STREAM, DELETE_STREAM]),
    ...mapActions(MESSAGE_MODULE, [DISPLAY_MESSAGE]),
    async toggleStatusStream(item) {
      if (this.isCreated(item)) {
        await this[START_STREAM](item);
      }

      if (this.isStarted(item)) {
        await this[STOP_STREAM](item);
      }
    },
    editStream(item) {
      this.selectedStream = item;
      this.modalTitle = 'edit';
      this.dialog.stream = true;
    },
    addStream() {
      this.modalTitle = 'add';
      this.dialog.stream = true;
    },
    toggleStatusConfirmation(item) {
      this.selectedStream = item;
      if (this.isCreated(item)) {
        this.dialog.start = true;
      }

      if (this.isStarted(item)) {
        this.dialog.stop = true;
      }
    },
    deleteStreamConfirmation(item) {
      this.selectedStream = item;
      this.dialog.delete = true;
    },
    async closeDialog(name) {
      this.selectedStream = null;
      this.dialog[name] = false;
      await this[GET_ALL_STREAMS]({ eventId: this.eventId });
    },
    async deleteDialogConfirm() {
      await this.deleteStream(this.selectedStream);
      await this.closeDialog('delete');
    },
    async deleteStream(item) {
      await this[DELETE_STREAM](item);
    },
    async startDialogConfirm() {
      await this.toggleStatusStream(this.selectedStream);
      await this.closeDialog('start');
    },
    async stopDialogConfirm() {
      await this.toggleStatusStream(this.selectedStream);
      await this.closeDialog('stop');
    },
    getStreamName(item) {
      return this.mergedConferencesAssembliesNames.get(item.conferenceId || item.generalAssemblyId);
    },
    isCreated(item) {
      return [StreamStatus.CREATED].includes(item.status);
    },
    isStopped(item) {
      return [StreamStatus.STOPPED].includes(item.status);
    },
    isStarted(item) {
      return [StreamStatus.STARTED].includes(item.status);
    },
    isDeleted(item) {
      return [StreamStatus.DELETED].includes(item.status);
    },
    canStartStream(item) {
      return this.canEditStreams && (this.isCreated(item) || this.isStopped(item));
    },
    canStopStream(item) {
      return this.canEditStreams && this.isStarted(item);
    },
    isStreamDisabled(item) {
      return (
        (this.isCreated(item) &&
          this.streams.some((stream) => stream.status === StreamStatus.STARTED)) ||
        this.isStopped(item)
      );
    },
    copyToClipboard(item) {
      navigator.clipboard.writeText(item.rtmp);
      this[DISPLAY_MESSAGE]({
        text: this.$t('admin.streams.copied'),
        type: MessageType.info,
      });
    },
  },
  async mounted() {
    await this[GET_CONFERENCES_FOR_EVENT](this.eventId);
    await this[GET_ALL_ASSEMBLIES](this.eventId);
    this.mergedConferencesAssembliesNames = new Map([
      ...this.conferenceIdNameMap,
      ...this.generalAssemblyIdNameMap,
    ]);
    await this[GET_ALL_STREAMS]({ eventId: this.eventId });
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

#streams {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.streams-list {
  &__title {
    margin-bottom: 10px;
  }
}

.rtmp {
  @include ellipsis();
  cursor: pointer;
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }

  &__some {
    color: var(--v-warning-base);
  }

  &__none {
    color: var(--v-error-base);
  }
}
</style>
