<template>
  <div class="content-items">
    <div class="content-items__actions mt-4">
      <v-btn
        data-test-id="add"
        class="actions__create"
        large
        depressed
        color="primary"
        :disabled="!canUpdate"
        :to="`/events/${event.id}/admin/content-libraries/${contentCategory.id}/edit/content-items/add`"
      >
        {{ $t('globals.add') }}
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <base-list
      class="mt-4"
      className="img-first"
      :headers="headers"
      :search="search"
      :items="contentItems"
      :loading="itemsAreLoading"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.coverImage`]="{ item }">
        <div class="container-img">
          <v-img class="container-img__img" :src="item.coverImage" v-if="item.coverImage" />
          <p class="container-img__placeholder" v-else>
            {{ $t('event.edit.noCover') }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <p class="truncate">{{ item.name }}</p>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <p class="truncate">{{ item.description }}</p>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="user"
          @edit-item="editContentItem(item)"
          :can-edit="canUpdate"
          @delete-item="deleteContentItem(item)"
          :can-delete="canDelete"
        />
      </template>
    </base-list>
  </div>
</template>

<script>
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';

export default {
  name: 'ContentItemsAdmin',
  components: {
    AdminTableActions,
    BaseList,
    AppSearch,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    contentItems: {
      type: Array,
      required: true,
    },
    contentCategory: {
      type: Object,
      required: true,
    },
    itemsAreLoading: {
      type: Boolean,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: () => false,
    },
    canDelete: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    headers() {
      return [
        {
          value: 'coverImage',
          width: '200px',
        },
        {
          text: this.$t('contentItems.list.columns.name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('contentItems.list.columns.description'),
          align: 'start',
          value: 'description',
          sortable: false,
        },
        {
          value: 'actions',
          width: '100px',
        },
      ];
    },
  },
  methods: {
    onSearchChange(search) {
      this.search = search;
    },
    editContentItem(item) {
      this.$emit('edit-item', item);
    },
    deleteContentItem(item) {
      this.$emit('delete-item', item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

@include admin-layout();

.content-items {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.truncate {
  @include ellipsis();
}

.container-img {
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    width: 100%;
    height: 100%;
  }

  &__placeholder {
    max-width: 100px;
    text-align: center;
    margin: 0;
  }
}

@include breakpoint(medium) {
  .container-img {
    width: 200px;
    height: 100px;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--v-gray-base);

    &__img {
      border-radius: 10px 0 0 10px;
    }
  }
}
</style>
