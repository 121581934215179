<template>
  <v-container id="partners">
    <h2 class="partners__title">{{ event.name }} - {{ $t('admin.partners.title') }}</h2>

    <partner-table
      backgroundColor="var(--v-adminBackground-base)"
      :partners="partners"
      :partners-are-loading="partnersAreLoading"
      :user="currentLoggedInUser"
      :search-bar="true"
      :can-add="canCreate"
      :can-edit="canUpdate"
      :can-delete="canDelete"
      @delete-partner="deletePartner"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PartnerTable from '@/views/admin/partners/partner-table/PartnerTable.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  DELETE_PARTNER,
  GET_PARTNERS,
  PARTNER_MODULE,
} from '@/stores/umanize-admin/actions/partners/partners.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'PartnersAdmin',
  components: {
    PartnerTable,
  },
  computed: {
    ...mapGetters(PARTNER_MODULE, ['partners', 'partnersAreLoading']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canDelete'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
  },
  methods: {
    ...mapActions(PARTNER_MODULE, [GET_PARTNERS, DELETE_PARTNER]),
    async deletePartner(partner) {
      await this[DELETE_PARTNER]({
        partner,
        conferenceId: this.$route.params.conferenceId,
        discussionGroupId: this.$route.params.discussionGroupId,
      });
      this.loadPartners();
    },
    loadPartners() {
      const { eventId } = this.$route.params;
      this[GET_PARTNERS]({ eventId });
    },
  },
  mounted() {
    this.loadPartners();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

#partners {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.partners {
  &__title {
    margin-bottom: 10px;
  }
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
