import { render, staticRenderFns } from "./ExhibitorsAdmin.vue?vue&type=template&id=3f0723c0&scoped=true&"
import script from "./ExhibitorsAdmin.vue?vue&type=script&lang=js&"
export * from "./ExhibitorsAdmin.vue?vue&type=script&lang=js&"
import style0 from "./ExhibitorsAdmin.vue?vue&type=style&index=0&id=3f0723c0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0723c0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VChip,VContainer,VIcon})
