<template>
  <v-container class="edit-ga">
    <v-form ref="form" v-model="valid" v-if="generalAssemblyForm" @submit.prevent="onSave">
      <v-row>
        <v-col>
          <v-text-field
            v-model="generalAssemblyForm.name"
            :rules="rules.NAME"
            :disabled="!canCreateOrEdit"
            :label="$t('admin.generalAssemblies.name')"
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <DateTimePicker
            :disabled="!canCreateOrEdit"
            :label="$t('admin.generalAssemblies.startTime')"
            v-model="generalAssemblyForm.startTime"
            :textFieldProps="{ rules: startTimeRules }"
          >
            <template slot="actions" slot-scope="{ parent }">
              <v-btn color="grey darken-1" text @click="parent.display = false">
                {{ $t('globals.cancel') }}
              </v-btn>

              <v-btn color="green darken-1" text @click="parent.okHandler">
                {{ $t('globals.ok') }}
              </v-btn>
            </template>

            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>

            <template slot="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </template>
          </DateTimePicker>
        </v-col>

        <v-col cols="12" sm="6">
          <DateTimePicker
            :disabled="!canCreateOrEdit"
            :label="$t('admin.generalAssemblies.endTime')"
            v-model="generalAssemblyForm.endTime"
            :textFieldProps="{ rules: endTimeRules }"
          >
            <template slot="actions" slot-scope="{ parent }">
              <v-btn color="grey darken-1" text @click="parent.display = false">
                {{ $t('globals.cancel') }}
              </v-btn>

              <v-btn color="green darken-1" text @click="parent.okHandler">
                {{ $t('globals.ok') }}
              </v-btn>
            </template>

            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>

            <template slot="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </template>
          </DateTimePicker>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea
            v-model="generalAssemblyForm.description"
            :disabled="!canCreateOrEdit"
            :label="$t('admin.generalAssemblies.description')"
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea
            v-model="generalAssemblyForm.agenda"
            :disabled="!canCreateOrEdit"
            :label="$t('admin.generalAssemblies.agenda')"
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" :sm="isZoom || isVimeo ? 12 : 6" :md="4">
          <v-select
            :disabled="!canCreateOrEdit"
            v-model="generalAssemblyForm.videoType"
            :items="videoTypes"
            :label="$t('admin.generalAssemblies.videoType')"
            hide-details="auto"
          />
        </v-col>

        <template v-if="isZoom">
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              :disabled="!canCreateOrEdit"
              v-model="generalAssemblyForm.zoomMeetingId"
              :label="$t('admin.generalAssemblies.zoomMeetingId')"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              :disabled="!canCreateOrEdit"
              v-model="generalAssemblyForm.zoomMeetingPassword"
              :label="$t('admin.generalAssemblies.zoomMeetingPassword')"
            />
          </v-col>
        </template>

        <template v-if="isVimeo">
          <v-col cols="12" sm="6" md="8">
            <v-text-field
              :disabled="!canCreateOrEdit"
              v-model="generalAssemblyForm.vimeoUrl"
              :label="$t('admin.generalAssemblies.vimeoUrl')"
              :rules="rules.VIMEO_URL"
            />
          </v-col>
        </template>

        <template v-if="isUmanize">
          <v-col cols="12" sm="6" md="8">
            <v-text-field
              v-model="generalAssemblyForm.vimeoUrl"
              :disabled="!canCreateOrEdit"
              hide-details="auto"
              :persistent-placeholder="true"
              placeholder="-"
              :label="$t('admin.generalAssemblies.videoUrl')"
            >
              <template v-slot:prepend>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>

                  <slot>{{ $t('admin.generalAssemblies.videoUrl-tooltip') }}</slot>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </template>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <p class="background-img__title">
            {{ $t('admin.generalAssemblies.background') }}
          </p>

          <v-input :rules="backgroundRules" v-model="generalAssemblyForm.background">
            <v-img
              class="img-container my-2"
              height="140"
              width="300"
              :src="generalAssemblyForm.background"
            >
              <p v-if="!generalAssemblyForm.background">
                {{ $t('event.edit.noBackground') }}
              </p>
            </v-img>
          </v-input>

          <div class="background-img__actions">
            <v-btn
              :disabled="!canCreateOrEdit"
              class="mr-3"
              color="primary"
              @click="openPictureDialog('background')"
            >
              {{ $t('globals.upload') }}
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-label>{{ $t('discussion-group.edit.cover-images.label') }}</v-label>

          <base-input-multiple
            :disabled="!canCreateOrEdit"
            :placeholder="$t('event.edit.cover-images.placeholder')"
            :values="generalAssemblyForm.coverImages"
            :has-internal-add="false"
            @on-change="onCoverImagesChange($event)"
            @add="openPictureDialog('coverImages')"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn color="primary" class="mx-2" outlined @click="goBackToList">
            {{ $t('globals.cancel') }}
          </v-btn>
          <v-btn
            class="mx-2"
            color="primary"
            :loading="false"
            :disabled="!canCreateOrEdit"
            type="submit"
          >
            {{ $t('globals.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <upload-pictures
      :dialog="pictureDialog"
      :is-multiple="multiplePicture"
      :height="pictureDialogHeight"
      :width="pictureDialogWidth"
      @save="uploadPictures"
      @close="closePictureDialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import set from 'lodash.set';

import { ALLOWED_VIDEO_TYPE, VideoType } from '@/models/general-assembly/video-type.model';
import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import validators from '@/helpers/validators/validators';

import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import BaseInputMultiple from '@/components/base-input-multiple/BaseInputMultiple.vue';

import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  ADMIN_ASSEMBLY_MODULE,
  SAVE_GENERAL_ASSEMBLY,
  UPDATE_GENERAL_ASSEMBLY,
} from '@/stores/umanize-admin/actions/general-assembly/general-assembly.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { GET_ASSEMBLY_BY_ID } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import DateTimePicker from '@/components/datetime-picker/DateTimePicker.vue';

export default {
  name: 'EditGeneralAssembliesAdmin',
  components: {
    DateTimePicker,
    BaseInputMultiple,
    UploadPictures,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    assemblyId: {
      type: String,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: false,
    rules: VALIDATORS.GENERAL_ASSEMBLIES,
    startTimeRules: VALIDATORS.GENERAL_ASSEMBLIES.START_TIME,
    pictureDialog: false,
    pictureField: null,
    multiplePicture: false,
    generalAssemblyForm: null,
    currentGeneralAssembly: null,
    videoTypes: ALLOWED_VIDEO_TYPE,
    pictureDialogHeight: 0,
    pictureDialogWidth: 0,
    pictureDialogType: null,
    pictureDialogConfig: {
      coverImages: {
        height: 480,
        width: 768,
      },
      background: {
        height: 1080,
        width: 1920,
      },
    },
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, { currentEvent: 'event' }),
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(ADMIN_ASSEMBLY_MODULE, ['generalAssembly', 'assemblyIsLoading']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    backgroundRules() {
      return [validators.required(this.$t('admin.generalAssemblies.form.background.required'))];
    },
    endTimeRules() {
      if (this.generalAssemblyForm.endTime && this.generalAssemblyForm.startTime) {
        return [
          ...VALIDATORS.GENERAL_ASSEMBLIES.END_TIME,
          VALIDATORS.DATE.GREATER_THAN(
            DateTime.fromJSDate(this.generalAssemblyForm.startTime),
            this.$t('event.edit.startDate'),
          ),
        ];
      }
      return VALIDATORS.GENERAL_ASSEMBLIES.END_TIME;
    },
    isVimeo() {
      return this.generalAssemblyForm.videoType === VideoType.VIMEO;
    },
    isZoom() {
      return this.generalAssemblyForm.videoType === VideoType.ZOOM;
    },
    isUmanize() {
      return this.generalAssemblyForm.videoType === VideoType.UMANIZE;
    },
    canCreateOrEdit() {
      if (this.editing) {
        return PermissionsUtil.isAuthorized(
          ['permission.generalAssemblies.canUpdate'],
          this.loggedInUserRoles,
          this.eventId,
        );
      }

      return PermissionsUtil.isAuthorized(
        ['permission.generalAssemblies.canCreate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_ASSEMBLY_MODULE, [
      GET_ASSEMBLY_BY_ID,
      SAVE_GENERAL_ASSEMBLY,
      UPDATE_GENERAL_ASSEMBLY,
    ]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    goBackToList() {
      this.$emit('closeDialog');
    },
    onCoverImagesChange(picturesUrl) {
      this.generalAssemblyForm.coverImages = picturesUrl;
    },
    openPictureDialog(field) {
      this.pictureDialogHeight = this.pictureDialogConfig[field].height;
      this.pictureDialogWidth = this.pictureDialogConfig[field].width;
      this.pictureField = field;
      this.pictureDialog = true;
      this.multiplePicture = field === 'coverImages';
    },
    closePictureDialog() {
      this.pictureField = null;
      this.pictureDialog = false;
    },
    async uploadPictures(pictures) {
      if (this.multiplePicture) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const picture of pictures) {
          await this[UPLOAD_FILE](picture);
          this.generalAssemblyForm[this.pictureField] = [
            ...(this.generalAssemblyForm[this.pictureField] || []),
            this.file.url,
          ];
        }
      } else {
        await this[UPLOAD_FILE](pictures[0]);
        set(this.generalAssemblyForm, this.pictureField, this.file.url);
      }
      this.closePictureDialog();
    },
    async onSave() {
      if (this.$refs.form && !this.$refs.form.validate()) return;

      if (this.editing) {
        // Do not override the chat room ID
        this.generalAssemblyForm.chatRoom = this.currentGeneralAssembly.chatRoom;
        await this[UPDATE_GENERAL_ASSEMBLY](this.generalAssemblyForm);
      } else {
        await this[SAVE_GENERAL_ASSEMBLY]({
          eventId: this.eventId,
          assembly: this.generalAssemblyForm,
        });
        this.$emit('save');
      }

      this.goBackToList();
    },
  },
  async mounted() {
    if (this.editing) {
      await this[GET_ASSEMBLY_BY_ID]({
        eventId: this.eventId,
        assemblyId: this.assemblyId,
      });
      this.currentGeneralAssembly = this.generalAssembly;
      this.generalAssemblyForm = {
        ...this.generalAssembly,
        startTime:
          this.generalAssembly.startTime &&
          DateTime.fromISO(this.generalAssembly.startTime).toJSDate(),
        endTime:
          this.generalAssembly.endTime && DateTime.fromISO(this.generalAssembly.endTime).toJSDate(),
      };
    } else {
      this.generalAssemblyForm = {
        eventId: this.eventId,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.edit-ga {
  min-height: calc(100vh - 6rem);
}

.background-img {
  &__title {
    font-weight: 600;
    margin: 0;
  }

  &__actions {
    display: flex;
  }
}

.img-container {
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}

.video-url {
  &__title {
    font-weight: $semi-bold;
    font-size: $small-font-size;
    margin-bottom: 0;
  }
}

::v-deep .v-text-field .v-label {
  font-weight: $semi-bold;
}
</style>
