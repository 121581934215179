<template>
  <v-container>
    <v-form v-model="valid" :disabled="!canCreate" @submit.prevent="save">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <p class="colors__title">{{ $t('admin.custom-ui.header.backgroundColor') }}</p>

            <v-color-picker
              @update:color="setHeaderBackgroundColor"
              dot-size="10"
              canvas-height="50"
              hide-mode-switch
              mode="hexa"
              :disabled="!canUpdate"
              v-model="headerBackgroundColor"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <p class="colors__title">{{ $t('admin.custom-ui.header.textColor') }}</p>

            <v-color-picker
              @update:color="setHeaderTextColor"
              dot-size="10"
              canvas-height="50"
              hide-mode-switch
              mode="hexa"
              :disabled="!canUpdate"
              v-model="headerTextColor"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="customUiForm.headerFont"
              :items="fontOptions"
              :label="$t('admin.custom-ui.header.fontLabel')"
              :disabled="!canUpdate"
              color="primary"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              class="form__field-email"
              v-model="headerSupportEmail"
              color="primaryFont"
              persistent-placeholder
              hide-details="auto"
              required
              autocomplete
              :disabled="!canUpdate"
              :rules="emailRules"
              maxlength="125"
              :label="$t('admin.custom-ui.header.email')"
              :placeholder="$t('admin.custom-ui.header.emailPlaceholder')"
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <p class="logo__title">
              {{ $t('admin.custom-ui.header.webLogo') }}
              <info-tooltip>{{ $t('admin.custom-ui.header.webLogoTooltip') }}</info-tooltip>
            </p>

            <v-img
              class="img-container"
              max-height="150"
              max-width="150"
              :contain="true"
              :src="customUiForm.headerLogoWeb"
            >
              <v-btn
                class="actions__delete"
                color="error"
                @click="removePicture('headerLogoWeb')"
                v-if="canUpdate && !!customUiForm.headerLogoWeb"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <p v-else>{{ $t('admin.custom-ui.header.noLogo') }}</p>
            </v-img>

            <v-btn
              v-if="canUpdate"
              class="mr-2 mt-3"
              color="primary"
              @click="openPictureDialog('headerLogoWeb')"
            >
              {{ $t('globals.upload') }}
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-card-actions class="actions">
          <v-btn color="primary" type="submit" :disabled="!valid || !canUpdate">
            {{ $t('globals.save') }}
          </v-btn>
        </v-card-actions>

        <upload-pictures
          :dialog="pictureDialog"
          :is-multiple="false"
          :height="pictureDialogHeight"
          :width="pictureDialogWidth"
          @save="uploadPicture"
          @close="closePictureDialog"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import set from 'lodash.set';
import { mapActions, mapGetters } from 'vuex';

import { CustomUI } from '@/models/custom-ui/custom-ui.model';
import VALIDATORS from '@/helpers/forms/validators.helper';

import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADMIN_CUSTOM_UI_MODULE,
  SAVE_CUSTOM_UI,
  CLEAR_CUSTOM_UI,
} from '@/stores/umanize-admin/actions/custom-ui/admin-custom-ui.actions';
import { GET_CUSTOM_UI } from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';

import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import InfoTooltip from '@/components/info-tooltip/InfoTooltip.vue';

export default {
  components: { UploadPictures, InfoTooltip },
  name: 'CustomUIHeader',
  props: {
    canUpdate: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerBackgroundColor: '#00B5A8',
      headerTextColor: '#FFFFFF',
      headerSupportEmail: 'support@umanize.com',
      customUiForm: new CustomUI(),
      emailRules: [VALIDATORS.EMAIL.REQUIRED, VALIDATORS.EMAIL.FORMAT],
      valid: true,
      dialog: false,
      fontOptions: ['Roboto', 'Poppins'],
      pictureDialog: false,
      pictureDialogHeight: 0,
      pictureDialogWidth: 0,
      pictureDialogConfig: {
        headerLogoWeb: {
          height: 200,
          width: 600,
        },
      },
    };
  },
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(ADMIN_CUSTOM_UI_MODULE, ['customUi']),
    placeholderValues() {
      return {
        headerBackgroundColor: '#00B5A8',
        headerTextColor: '#FFFFFF',
        headerSupportEmail: 'support@umanize.com',
      };
    },
    colors() {
      return `background-color: ${this.headerBackgroundColor};color: ${this.headerTextColor}`;
    },
  },
  methods: {
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    ...mapActions(ADMIN_CUSTOM_UI_MODULE, [GET_CUSTOM_UI, CLEAR_CUSTOM_UI, SAVE_CUSTOM_UI]),
    buttonName(btn) {
      return this.customUiForm[btn] || this.placeholderValues[btn];
    },
    setHeaderBackgroundColor(color) {
      this.headerBackgroundColor = color.hex;
    },
    setHeaderTextColor(color) {
      this.headerTextColor = color.hex;
    },
    removePicture(prop) {
      set(this.customUiForm, prop, null);
    },
    openPictureDialog(type) {
      this.pictureDialogHeight = this.pictureDialogConfig[type].height;
      this.pictureDialogWidth = this.pictureDialogConfig[type].width;
      this.dialogType = type;
      this.pictureDialog = true;
    },
    closePictureDialog() {
      this.dialogType = null;
      this.pictureDialog = false;
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      set(this.customUiForm, this.dialogType, this.file.url);
      this.closePictureDialog();
    },
    async save() {
      const form = { ...this.customUiForm };
      form.headerBackgroundColor = this.headerBackgroundColor;
      form.headerTextColor = this.headerTextColor;
      form.headerSupportEmail = this.headerSupportEmail;
      await this[SAVE_CUSTOM_UI](form);
    },
  },
  async mounted() {
    const { eventId } = this.$route.params;
    await this[GET_CUSTOM_UI]({ eventId });
    this.customUiForm = { ...this.customUi, eventId };
    this.headerBackgroundColor =
      this.customUiForm.headerBackgroundColor || this.placeholderValues.headerBackgroundColor;
    this.headerTextColor =
      this.customUiForm.headerTextColor || this.placeholderValues.headerTextColor;
    this.customUiForm.headerFont = this.customUiForm.headerFont || 'Roboto';
    this.headerSupportEmail =
      this.customUiForm.headerSupportEmail || this.placeholderValues.headerSupportEmail;
  },
  async beforeDestroy() {
    await this[CLEAR_CUSTOM_UI]();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';
@include admin-layout();

.img-container {
  height: 150px;
  width: 150px;
  border: 1px solid var(--v-gray-base);
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
