<template>
  <v-container class="edit-content-item">
    <h1 class="edit-content-item__title">
      {{ event.name }} -
      {{ isEditing ? $t('contentItem.edit.title') : $t('contentItem.add.title') }}
    </h1>

    <v-container class="edit-content-item__content">
      <v-form v-model="isValid" class="form" :disabled="!canUpdate">
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="contentItemForm.name"
              :rules="rules.name"
              :label="$t('contentItem.edit.name')"
              hide-details="auto"
              :persistent-placeholder="true"
              placeholder="-"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="contentItemForm.description"
              :rules="rules.description"
              :label="$t('contentItem.edit.description')"
              hide-details="auto"
              :persistent-placeholder="true"
              placeholder="-"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p class="content__title">{{ $t('contentItem.edit.contentType') }}</p>

            <div class="content__select">
              <v-select
                v-model="selectedType"
                :items="types"
                item-value="value"
                item-text="text"
                @change="cleanContent"
                hide-details
                data-test-id="select-type"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p class="content__title">{{ $t('contentItem.edit.content') }}</p>

            <template v-if="isContentType('video')">
              <v-text-field
                :label="$t('contentItem.edit.videoUrl')"
                :persistent-placeholder="true"
                placeholder="-"
                class="content__input mr-3"
                v-model="models.video"
                :rules="rules.videoFormat"
                @input="addUrl($event, 'video')"
              />
              <span>{{ $t('globals.or') }}</span>
              <v-btn
                class="ml-3"
                color="primary"
                @click="openDialog('video')"
                :disabled="videoIsSending || !canUpdate"
                :loading="videoIsSending"
              >
                {{ $t('globals.upload') }}
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>

            <template v-if="isContentType('link')">
              <v-text-field
                :label="$t('contentItem.edit.linkUrl')"
                :persistent-placeholder="true"
                placeholder="-"
                class="content__input mr-3"
                v-model="models.link"
                :rules="rules.url"
                @input="addUrl($event, 'link')"
              />
            </template>

            <template v-if="isContentType('file')">
              <v-text-field
                :label="$t('contentItem.edit.type.file')"
                :disabled="!models.file"
                :persistent-placeholder="true"
                placeholder="-"
                readonly
                class="content__input mr-3"
                v-model="models.file"
                :append-outer-icon="!!models.file ? 'mdi-close' : ''"
                @click:append-outer="cleanContent"
              />

              <v-btn v-if="!models.file" class="ml-3" color="primary" @click="openDialog('file')">
                {{ $t('globals.upload') }}
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
          </v-col>
        </v-row>

        <div v-if="hasContentVideoUrl && !!video" class="mb-8">
          <p>{{ $t('admin.video.preview') }}</p>

          <div class="video-placeholder my-5" v-if="videoIsProcessing">
            <v-btn
              class="mr-2"
              fab
              depressed
              x-small
              :disabled="videoIsProcessing"
              :loading="videoIsProcessing"
            />
            <span>{{ $t('kioskBuilder.edit.kiosk-builder.videoIsProcessing') }}</span>
          </div>

          <iframe
            id="video-iframe"
            v-if="!videoIsProcessing"
            :src="contentItemForm.content[0].url[0]"
            max-width="200"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>

        <v-checkbox v-model="hasCoverImage" :label="$t('contentItem.edit.addCoverImage')" />

        <template v-if="hasCoverImage">
          <p class="cover-img__title">
            {{ $t('contentItem.edit.coverImage') }}
          </p>

          <v-img
            class="img-container my-2"
            height="140"
            width="300"
            :src="contentItemForm.coverImage"
          >
            <p v-if="!contentItemForm.coverImage">
              {{ $t('event.edit.noCover') }}
            </p>
          </v-img>

          <div v-if="canUpdate" class="cover-img__actions">
            <v-btn class="mr-3" color="primary" @click="openPictureDialog('coverImage')">
              {{ $t('globals.upload') }}
              <v-icon>mdi-upload</v-icon>
            </v-btn>

            <v-btn color="error" @click="removeCoverImage()">
              {{ $t('globals.reset') }}
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-form>

      <v-card-actions class="actions">
        <v-btn data-test-id="cancel" color="primary" outlined @click="goBackToList">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn
          data-test-id="add"
          :disabled="
            !isValid ||
            (!contentItemForm.coverImage && hasCoverImage) ||
            !hasContent ||
            itemIsSaving ||
            !canUpdate
          "
          :loading="itemIsSaving"
          color="primary"
          @click="save"
        >
          {{ isEditing ? $t('globals.save') : $t('contentLibraries.add.add') }}
        </v-btn>
      </v-card-actions>

      <upload-pictures
        :dialog="dialog.picture"
        :is-multiple="false"
        :height="pictureHeight"
        :width="pictureWidth"
        @save="uploadPicture"
        @close="closePictureDialog"
      />

      <upload-videos
        :dialog="dialog.video"
        @save="uploadVideo"
        @close="closeDialog('video')"
        :isLoading="videoIsSending"
      />

      <upload-files
        :is-multiple="false"
        :dialog="dialog.file"
        @save="uploadFile"
        @close="closeDialog('file')"
      />
    </v-container>
  </v-container>
</template>

<script>
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import { mapGetters, mapActions } from 'vuex';

import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import UploadVideos from '@/components/admin/upload-videos/UploadVideos.vue';
import UploadFiles from '@/components/admin/upload-files/UploadFiles.vue';

import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  ADMIN_CONTENT_MODULE,
  CLEAR_CONTENT_ITEM,
  SAVE_CONTENT_ITEM,
  UPLOAD_CONTENT_ITEM_VIDEO,
  DELETE_CONTENT_ITEM_VIDEO,
} from '@/stores/umanize-admin/actions/content/admin-content.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { GET_CONTENT_ITEM } from '@/stores/agnostic/actions/content/agnostic-content.actions';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';

export default {
  name: 'EditContentItemAdmin',
  components: {
    UploadPictures,
    UploadVideos,
    UploadFiles,
  },
  data() {
    return {
      defaultContentItem: {
        id: '',
        eventId: '',
        libraryId: '',
        name: '',
        description: '',
        coverImage: '',
        content: [
          {
            name: '',
            type: '',
            url: [],
          },
        ],
      },
      contentItemForm: {
        id: '',
        eventId: '',
        libraryId: '',
        name: '',
        description: '',
        coverImage: '',
        content: [
          {
            name: '',
            type: '',
            url: [],
          },
        ],
      },
      dialog: {
        picture: false,
        video: false,
        file: false,
      },
      pictureDialogType: null,
      pictureWidth: 800,
      pictureHeight: 450,
      videoIsProcessing: false,
      defaultModels: {
        video: '',
        link: '',
        file: '',
      },
      models: {
        video: '',
        link: '',
        file: '',
      },
      isValid: false,
      types: [
        { value: null, text: this.$t('contentItem.edit.type.select'), disabled: true },
        { value: 'video', text: this.$t('contentItem.edit.type.video') },
        { value: 'file', text: this.$t('contentItem.edit.type.file') },
        { value: 'link', text: this.$t('contentItem.edit.type.link') },
      ],
      selectedType: null,
      hasCoverImage: false,
      rules: {
        name: VALIDATORS.REQUIRED.NAME,
        description: VALIDATORS.REQUIRED.DESCRIPTION,
        videoFormat: [VALIDATORS.VIDEO.TYPE.IS_VIMEO_OR_YOUTUBE],
        url: [VALIDATORS.URL.FORMAT, VALIDATORS.LENGTH.MAX(256, 'URL')],
      },
    };
  },
  computed: {
    ...mapGetters(ADMIN_CONTENT_MODULE, [
      'contentCategory',
      'contentItem',
      'itemIsSaving',
      'video',
      'videoIsSending',
    ]),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    isEditing() {
      const count = (this.$route.path.match(/edit/g) || []).length;
      return count === 2;
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.contentLibraries.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    hasContentVideoUrl() {
      return !!this.contentItem?.content[0]?.url;
    },
    hasContent() {
      return !!this.models.link || !!this.models.video || this.models.file;
    },
  },
  methods: {
    ...mapActions(ADMIN_CONTENT_MODULE, [
      SAVE_CONTENT_ITEM,
      CLEAR_CONTENT_ITEM,
      GET_CONTENT_ITEM,
      UPLOAD_CONTENT_ITEM_VIDEO,
      DELETE_CONTENT_ITEM_VIDEO,
    ]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    initForm() {
      this.contentItemForm = {
        ...this.defaultContentItem,
        ...cloneDeep(this.contentItem),
        eventId: this.$route.params.eventId,
        libraryId: this.$route.params.contentLibraryId,
      };
    },
    goBackToList() {
      this.$router.back();
    },
    openDialog(type) {
      this.dialog[type] = true;
    },
    closeDialog(type) {
      this.dialog[type] = false;
    },
    openPictureDialog(type) {
      this.dialogType = type;
      this.openDialog('picture');
    },
    closePictureDialog() {
      this.dialogType = null;
      this.closeDialog('picture');
    },
    removeCoverImage() {
      this.contentItemForm.coverImage = null;
    },
    async save() {
      const videoUrl = this.contentItemForm.content[0].url[0];

      this.contentItemForm.content[0] = {
        ...this.contentItemForm.content[0],
        name: this.contentItemForm.name,
        url: [
          videoUrl.includes('youtu.be')
            ? videoUrl.replace('https://youtu.be/', 'https://www.youtube.com/embed/')
            : videoUrl,
        ],
      };

      if (!this.hasCoverImage) {
        this.contentItemForm.coverImage = null;
      }

      await this[SAVE_CONTENT_ITEM](this.contentItemForm);
      this.goBackToList();
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      set(this.contentItemForm, this.dialogType, this.file.url);

      this.closePictureDialog();
    },
    addUrl(url, type) {
      this.contentItemForm.content[0].type = type;
      this.contentItemForm.content[0].url[0] = url;
    },
    async uploadVideo(video) {
      const { eventId, contentLibraryId } = this.$route.params;

      await this[UPLOAD_CONTENT_ITEM_VIDEO]({
        eventId,
        contentLibraryId,
        video,
      });
      this.videoIsProcessing = true;
      this.contentItemForm.content[0].url[0] = this.video.playableUrl;
      this.models = {
        ...this.defaultModels,
        video: this.video.playableUrl,
      };

      this.closeDialog('video');

      setTimeout(() => {
        this.videoIsProcessing = false;
      }, 10000);
    },
    async uploadFile(files) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const file of files) {
        await this[UPLOAD_FILE](file);
        this.contentItemForm.content[0] = {
          name: file.name,
          type: 'file',
          url: [this.file.url],
        };

        this.models = {
          ...this.defaultModels,
          file: file.name,
        };
      }

      this.closeDialog('file');
    },
    cleanContent() {
      this.contentItemForm.content = cloneDeep(this.defaultContentItem.content);
      this.models = cloneDeep(this.defaultModels);
      this[DELETE_CONTENT_ITEM_VIDEO]();
    },
    isContentType(type) {
      return this.selectedType === type;
    },
  },
  async mounted() {
    const { eventId, contentLibraryId, contentItemId } = this.$route.params;
    if (contentItemId) {
      await this[GET_CONTENT_ITEM]({
        eventId,
        categoryId: contentLibraryId,
        contentItemId,
      });
    }

    this.initForm();

    this.selectedType = this.contentItem?.content[0]?.type || this.types[0];
    this.hasCoverImage = !!this.contentItem?.coverImage;
    this.models[this.selectedType] = this.contentItemForm?.content[0]?.url[0];
  },
  async beforeDestroy() {
    this.cleanContent();
    await this[CLEAR_CONTENT_ITEM]();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.edit-content-item {
  padding: 50px 25px;
  min-height: calc(100vh - 6rem);

  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    padding: 20px 40px;
  }
}

.content {
  &__input {
    display: inline-flex;
    width: 50%;
  }

  &__col {
    display: flex;
  }

  &__button {
    align-self: center;
  }

  &__select {
    max-width: 50%;
    display: flex;

    & > * {
      padding: 0;
      margin: 0;
    }
  }
}

.content,
.cover-img,
.files {
  &__title {
    font-weight: 600;
    margin: 0;
  }
}

.img-container {
  height: 150px;
  width: 150px;
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}

::v-deep .v-text-field .v-label {
  font-weight: $semi-bold;
}

.video-placeholder {
  font-size: $small-font-size;
}
</style>
