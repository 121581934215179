<template>
  <v-card>
    <v-card-text class="full-height">
      <v-stepper class="elevation-0" v-model="stepper">
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="stepper > 1" step="1">
            {{ $t('admin.users.stepper1') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="stepper > 2" step="2">
            {{ $t('admin.users.stepper2') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="stepper > 3" step="3">
            {{ $t('admin.users.stepper3') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-content class="stepper-content" step="singleOrMultiple">
          <v-btn>Single</v-btn>
          <v-btn>Multiple</v-btn>
        </v-stepper-content>
        <v-stepper-content class="stepper-content" step="1">
          <p>{{ $t('admin.users.uploadText') }}</p>
          <p>{{ $t('admin.users.selectTicket') }}</p>
          <v-select
            :items="tickets"
            item-value="id"
            item-text="name"
            v-model="selectedTicket"
            :label="$t('admin.users.selectTicketInput')"
          />

          <div class="actions">
            <v-btn class="actions__button" color="primary" outlined @click="onBack">
              {{ $t('globals.back') }}
            </v-btn>
            <v-btn class="actions__button" color="primary" outlined @click="onCancel">
              {{ $t('globals.cancel') }}
            </v-btn>
            <v-btn
              class="actions__button"
              color="primary"
              @click="stepper = 2"
              :disabled="!selectedTicket"
            >
              {{ $t('globals.continue') }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content class="stepper-content" step="2">
          <span>{{ $t('admin.users.selectFile') }}</span>
          <v-file-input v-model="file" accept=".csv" :label="$t('admin.users.placeholder')" />
          <div class="csv-content">
            {{ $t('admin.users.csv') }}
            <ul>
              <li>
                <pre>lastName</pre>
              </li>
              <li>
                <pre>firstName</pre>
              </li>
              <li>
                <pre>email</pre>
              </li>
            </ul>
          </div>

          <div class="actions">
            <v-btn class="actions__button" color="primary" outlined @click="onCancel">
              {{ $t('globals.cancel') }}
            </v-btn>
            <v-btn class="actions__button" color="primary" @click="validateFile" :disabled="!file">
              {{ $t('globals.continue') }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content class="stepper-content" step="3">
          <v-data-table
            :items="filteredItems"
            :headers="fileHeaders"
            :loading="!fileData && !fileDataErrors"
            :footer-props="{
              itemsPerPageText: $t('globals.datatable.itemPerPage'),
              itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
              pageText: $t('globals.datatable.of'),
            }"
            :no-data-text="$t('globals.datatable.noData')"
            :no-results-text="$t('globals.datatable.noResult')"
          >
            <template v-slot:top>
              <div class="table-header">
                <span
                  v-if="!allLinesDeleted"
                  :class="{ 'error-status': fileDataErrors.errorCount > 0 }"
                >
                  {{ $tc('admin.users.file.errorCount', fileDataErrors.errorCount) }}
                </span>

                <span v-if="allLinesDeleted" class="error-status">
                  {{ $tc('admin.users.file.allLinesDeleted') }}
                </span>

                <v-spacer />

                <v-checkbox v-model="seeAll" :label="$t('admin.users.file.seeAll')"></v-checkbox>
              </div>
            </template>

            <template v-slot:[`item.email`]="{ item }">
              <span :class="{ 'error-status': isBadEmail(item) }">{{ item.email }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="table-actions">
                <v-btn v-if="!item.deleted" icon @click="removeItem(item.index)">
                  <v-icon :title="$t('globals.delete')">mdi-delete</v-icon>
                </v-btn>
                <v-btn v-if="item.deleted" icon @click="placeItemBack(item.index)">
                  <v-icon :title="$t('globals.restore')">mdi-delete-restore</v-icon>
                </v-btn>
                <v-btn icon @click="editItem(item)">
                  <v-icon :title="$t('globals.edit')">mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:[`item.errors`]="{ item }">
              <div v-if="item.deleted">{{ $t('admin.users.file.errors.deleted') }}</div>
              <div class="error-status" v-if="isBadEmail(item)">
                {{ $t('admin.users.file.errors.email') }}
              </div>
              <div class="error-status" v-if="isDuplicateEventUser(item)">
                {{ $t('admin.users.file.errors.exists') }}
              </div>
              <div class="error-status" v-if="isDuplicateFileUser(item)">
                {{ $t('admin.users.file.errors.duplicate') }}
              </div>
            </template>
          </v-data-table>

          <div class="actions">
            <v-btn class="actions__button" outlined color="primary" @click="onCancel">
              {{ $t('globals.cancel') }}
            </v-btn>
            <v-btn
              class="actions__button"
              color="primary"
              :disabled="!canImportFile"
              @click="importFile"
            >
              {{ $t('globals.import') }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
    <v-dialog content-class="v-dialog--overlay" v-model="editDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('admin.users.file.editContent') }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.email" label="Email" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.firstName" label="FirstName" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.lastName" label="LastName" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.updatePassword" label="UpdatePassword" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.sendNotification" label="SendNotification" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" outlined @click="closeEditDialog">
            {{ $t('globals.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="saveEditedItem">{{ $t('globals.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import FileUtil from '@/helpers/file/file.helper';

export default {
  name: 'UploadUsersFromFile',
  props: ['onCancel', 'onBack', 'tickets', 'eventUsers'],
  data: () => ({
    isOpened: false,
    editDialog: false,
    seeAll: false,
    stepper: 1,
    selectedTicket: null,
    file: null,
    fileData: [],
    fileDataErrors: {},
    editedItem: {},
    fileHeaders: [
      {
        text: 'email',
        value: 'email',
      },
      {
        text: 'firstName',
        value: 'firstName',
      },
      {
        text: 'lastName',
        value: 'lastName',
      },
      {
        text: 'updatePassword',
        value: 'updatePassword',
      },
      {
        text: 'sendNotification',
        value: 'sendNotification',
      },
      {
        text: 'errors',
        value: 'errors',
      },
      {
        value: 'actions',
      },
    ],
  }),
  computed: {
    canImportFile() {
      return !this.allLinesDeleted && this.fileDataErrors.errorCount === 0;
    },
    filteredItems() {
      return this.seeAll
        ? this.fileData
        : this.fileData.filter(
            (i) =>
              this.fileDataErrors.errors.length &&
              this.fileDataErrors.errors[i.index]?.errorCount > 0,
          );
    },
    allLinesDeleted() {
      return this.fileData.filter((item) => !item.deleted).length === 0;
    },
  },
  methods: {
    editItem(item) {
      this.editedItem = {
        ...item,
      };
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editedItem = {};
      this.editDialog = false;
    },
    saveEditedItem() {
      this.fileData.splice(this.editedItem.index, 1, this.editedItem);
      this.editDialog = false;
      this.checkForErrors();
    },
    removeItem(index) {
      this.fileData[index].deleted = true;
      this.checkForErrors();
    },
    placeItemBack(index) {
      this.fileData[index].deleted = false;
      this.checkForErrors();
    },
    isBadEmail(item) {
      return this?.fileDataErrors?.errors[item.index]?.badEmail;
    },
    isDuplicateEventUser(item) {
      return this?.fileDataErrors?.errors[item.index]?.alreadyExists;
    },
    isDuplicateFileUser(item) {
      return this?.fileDataErrors?.errors[item.index]?.duplicate;
    },
    checkForErrors() {
      this.fileDataErrors = FileUtil.findErrors({
        fileUsers: this.fileData,
        eventUsers: this.eventUsers,
      });
    },
    async validateFile() {
      const reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = () => {
        this.fileData = FileUtil.convertUsersCsvToCsvUsers(reader.result);
        this.checkForErrors();
        this.stepper = 3;
      };
    },
    close() {
      this.file = null;
      this.selectedTicket = null;
      this.stepper = 1;
      this.$emit('close');
    },
    importFile() {
      const data = FileUtil.convertUsersToCsvString(this.fileData);
      const file = new File([data], 'file', { type: 'text/plain' });
      this.$emit('importFile', {
        file,
        ticketId: this.selectedTicket,
      });
      this.close();
    },
  },
  watch: {
    opened(value) {
      this.isOpened = value;
    },
  },
  mounted() {
    this.isOpened = this.opened;
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.error-status {
  color: var(--v-error-base);
  font-weight: bold;
}

.csv-content {
  margin-bottom: 1rem;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-actions {
  display: flex;
  align-items: center;
}

.actions {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__button {
    margin: 0 0.5rem;
  }
}
</style>
