<template>
  <div id="addGroupChatAdmin" @submit.prevent @submit="save">
    <v-form v-model="valid" :disabled="formIsDisabled">
      <v-row>
        <v-col cols="12">
          <v-text-field
            class="full"
            :label="$t('admin.groupChat.headers.name')"
            v-model="groupChatForm.name"
            :rules="rules.name"
            :persistent-placeholder="true"
            placeholder="-"
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <v-row class="align-end">
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="groupChatForm.userIds"
            :label="$t('admin.groupChat.headers.users')"
            :items="usersItems"
            dense
            chips
            small-chips
            multiple
            hide-details="auto"
            persistent-placeholder
            placeholder="-"
            :no-data-text="$t('globals.datatable.noResult')"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>

              <span v-if="index === 1" class="mr-2">
                {{
                  $tc('chat.name', groupChatForm.userIds.length, {
                    count: groupChatForm.userIds.length - 1,
                  })
                }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-if="ticketTypeIsSelected"
            class="full"
            :label="$t('admin.groupChat.headers.ticketType')"
            :items="ticketsItems"
            name="value"
            v-model="ticketType"
            :rules="rules.ticketType"
            multiple
            hide-details="auto"
            persistent-placeholder
            placeholder="-"
            data-test-id="tickets-field"
            @change="onSelectTicketType"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <users-list :users="selectedUsersEditable" @change-role="onChangeRole" />
        </v-col>
      </v-row>

      <v-card-actions class="actions">
        <v-btn outlined color="primary" @click="goBackToList" data-test-id="cancel-btn">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn color="primary" type="submit" :disabled="isDisabled" data-test-id="submit-btn">
          {{ $t('globals.save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

import VALIDATORS from '@/helpers/forms/validators.helper';

import { GroupChat } from '@/models/group-chat/group-chat.model';

import UsersList from '@/views/admin/group-chat/users-list/GroupChatUsersListAdmin.vue';

export default {
  name: 'AddGroupChatAdmin',
  components: {
    UsersList,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
    selectedChat: {
      type: Object,
      default: () => new GroupChat(),
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    isTypeAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultGroupChat: new GroupChat(),
      groupChatForm: new GroupChat(),
      rules: {
        name: VALIDATORS.GROUP_CHAT.NAME,
      },
      valid: false,
      usersItems: [],
      selectedUsersEditable: [],
      adminIds: [],
      ticketType: [],
    };
  },
  computed: {
    formIsDisabled() {
      return this.isTypeAdd ? !this.canCreate : !this.canUpdate;
    },
    selectedUsersItems() {
      return this.sorted(
        this.usersItems
          .filter(
            (ui) => this.groupChatForm.userIds.includes(ui.value) && ui.value !== 'TICKET_TYPE',
          )
          .map((u) => ({ ...u, isAdmin: this.adminIds.includes(u.value) })),
        'text',
      );
    },
    ticketTypeIsSelected() {
      return this.groupChatForm.userIds.includes('TICKET_TYPE') || !!this.ticketType.length;
    },
    sorted() {
      return (array, element) => array.sort((a, b) => a[element].localeCompare(b[element]));
    },
    isDisabled() {
      return (
        !this.valid ||
        this.formIsDisabled ||
        !this.groupChatForm.userIds.length ||
        !this.groupChatForm.adminIds?.length
      );
    },
  },
  methods: {
    goBackToList() {
      this.$emit('cancel');
    },
    async save() {
      if (this.isTypeAdd) {
        this.$emit('add', this.groupChatForm);
      } else {
        this.$emit('edit', this.groupChatForm);
      }
    },
    formatForSelect(array) {
      return array.map((value) => ({
        text: `${value.firstName} ${value.lastName}`,
        value: value.id,
        isAdmin: this.adminIds.includes(value.id),
      }));
    },
    addByTicket() {
      this.usersItems.unshift({ text: 'Users by ticket type', value: 'TICKET_TYPE' });
    },
    onChangeRole(user) {
      const userIdx = this.selectedUsersEditable.findIndex((u) => u.value === user.value);
      const adminIdIdx = this.adminIds.findIndex((u) => u === user.value);
      const isAdmin = !user.isAdmin;

      if (isAdmin && adminIdIdx === -1) {
        this.adminIds.push(user.value);
      } else {
        this.adminIds = this.adminIds.filter((adminId) => adminId !== user.value);
      }

      this.selectedUsersEditable[userIdx] = {
        ...this.selectedUsersEditable[userIdx],
        isAdmin,
      };

      this.groupChatForm.adminIds = this.adminIds;
    },
    onSelectTicketType(item) {
      let newSelectedUsers = this.users.filter((ui) => item.includes(ui.ticket.ticketId));

      newSelectedUsers = this.formatForSelect(
        newSelectedUsers.filter(
          (newSelectedUser) => !this.selectedUsersEditable.includes(newSelectedUser),
        ),
      );

      this.selectedUsersEditable = newSelectedUsers;
      this.groupChatForm.userIds = newSelectedUsers.map((newSelectedUser) => newSelectedUser.value);

      if (this.ticketType.length) {
        this.usersItems.splice(0, 1);
      } else {
        this.addByTicket();
      }
    },
  },
  mounted() {
    this.usersItems = this.sorted(this.formatForSelect(this.users), 'text');
    this.addByTicket();

    this.ticketsItems = this.tickets.map((t) => ({ text: t.name, value: t.id }));

    this.groupChatForm.name = this.selectedChat.name;
    this.groupChatForm.adminIds = this.selectedChat.adminIds || [];
    this.groupChatForm.userIds = this.usersItems
      .filter((ui) => this.selectedChat?.userIds?.includes(ui.value))
      .map((user) => user.value);

    this.adminIds = this.selectedChat.adminIds || [];
    this.selectedUsersEditable = this.selectedUsersItems;
  },
  async beforeDestroy() {
    this.groupChatForm = cloneDeep(this.defaultGroupChat);
  },
  watch: {
    selectedUsersItems(value) {
      this.selectedUsersEditable = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/mixins';

@include admin-layout();
</style>
