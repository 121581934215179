<template>
  <div>
    <base-list
      class="mt-4"
      backgroundColor="var(--v-adminBackground-base)"
      v-model="dataSelectedDiscussionGroups"
      :showSelect="showSelect"
      :headers="headers"
      :items="discussionGroups"
      :search="search"
      :loading="discussionGroupsAreLoading"
      :itemsPerPage="10"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ discussionGroupName(item.name) }}
      </template>

      <template v-slot:[`item.description`]="{ item }">
        {{ discussionGroupDescription(item.description) }}
      </template>

      <template v-slot:[`item.startTime`]="{ item }">
        {{ $d(Date.parse(item.startTime), 'long') }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="user"
          :can-edit="canEdit"
          :can-delete="canDelete"
          :is-disabled="hasPartners(item.id)"
          :disabled-tooltip="$t('admin.discussionGroups.delete.hasContent')"
          @edit-item="editDiscussionGroup(item)"
          @delete-item="deleteDiscussionGroup(item)"
        />
      </template>
    </base-list>
  </div>
</template>

<script>
import StringUtils from '@/helpers/string/string.helper';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';

const NAME_SIZE_LIMIT = 100;
const DESCRIPTION_SIZE_LIMIT = 120;

export default {
  name: 'DiscussionGroupsTable',
  components: { AdminTableActions, BaseList },
  props: {
    discussionGroups: {
      type: Array,
      default: () => [],
    },
    discussionGroupsWithPartners: {
      type: Array,
      default: () => [],
    },
    discussionGroupsAreLoading: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selectedDiscussionGroups: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataSelectedDiscussionGroups: {
      get() {
        return this.selectedDiscussionGroups;
      },
      set(value) {
        this.$emit('changedSelectedDiscussionGroups', value);
      },
    },
    headers() {
      return [
        {
          text: this.$t('admin.discussionGroups.headers.name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('admin.discussionGroups.headers.description'),
          value: 'description',
          sortable: false,
        },
        {
          text: this.$t('admin.discussionGroups.headers.startTime'),
          value: 'startTime',
          sortable: false,
        },
        {
          value: 'actions',
          align: 'center',
          width: '100px',
        },
      ];
    },
  },
  methods: {
    editDiscussionGroup(item) {
      this.$emit('edit-discussion-group', item);
    },
    deleteDiscussionGroup(item) {
      this.$emit('delete-discussion-group', item);
    },
    discussionGroupDescription(description) {
      return StringUtils.shorten(description, DESCRIPTION_SIZE_LIMIT);
    },
    discussionGroupName(name) {
      return StringUtils.shorten(name, NAME_SIZE_LIMIT);
    },
    hasPartners(discussionGroupId) {
      return this.discussionGroupsWithPartners.includes(discussionGroupId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
