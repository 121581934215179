<template>
  <v-stepper class="elevation-0" v-model="stepper">
    <v-stepper-header class="elevation-0">
      <v-stepper-step :complete="stepper >= 1" step="1">
        {{ $t(`admin.tickets.step1.${type}`) }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step :complete="stepper >= 2" step="2">
        {{ $t(`admin.tickets.step2.${type}`) }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-content class="stepper-content" step="1">
      <edit-ticket-form
        v-if="isLoaded"
        :ticketForm="ticketForm"
        :canUpdateTickets="canUpdateTickets"
        :eventId="eventId"
        @close="goBackToList"
        @next-step="next"
      />
    </v-stepper-content>
    <v-stepper-content class="stepper-content" step="2">
      <edit-ticket-profile-form
        :ticketForm="ticketForm"
        :canUpdateTickets="canUpdateTickets"
        :isCreating="isCreating"
        @close="goBackToList"
        @save="save"
      />
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';

import { Ticket } from '@/models/ticketing/ticketing.model';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { GET_ALL_ASSEMBLIES } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import { SELECT_TICKET } from '@/stores/agnostic/actions/ticket/agnostic-ticket.actions';

import {
  ADMIN_TICKET_MODULE,
  CLEAR_SELECTED_TICKET,
  GET_ADMIN_TICKETS,
  SAVE_TICKET,
} from '@/stores/umanize-admin/actions/ticket/admin-ticket.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { ADMIN_ASSEMBLY_MODULE } from '@/stores/umanize-admin/actions/general-assembly/general-assembly.actions';

import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import EditTicketForm from '@/views/admin/tickets/edit/EditTicketForm.vue';
import EditTicketProfileForm from '@/views/admin/tickets/edit/EditTicketProfileForm.vue';

export default {
  name: 'EditTicketAdmin',
  components: {
    EditTicketForm,
    EditTicketProfileForm,
  },
  props: {
    eventId: {
      type: String,
    },
    ticketId: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data: () => ({
    isLoaded: false,
    defaultTicket: new Ticket(),
    ticketForm: new Ticket(),
    stepper: 1,
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(ADMIN_TICKET_MODULE, ['tickets', 'selectedTicket']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(ADMIN_ASSEMBLY_MODULE, ['generalAssemblies']),
    canUpdateTickets() {
      return PermissionsUtil.isAuthorized(
        ['permission.tickets.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    isCreating() {
      return this.type === 'create';
    },
  },
  methods: {
    ...mapActions(ADMIN_TICKET_MODULE, [
      GET_ADMIN_TICKETS,
      SELECT_TICKET,
      SAVE_TICKET,
      CLEAR_SELECTED_TICKET,
    ]),
    ...mapActions(ADMIN_ASSEMBLY_MODULE, [GET_ALL_ASSEMBLIES]),

    initFormFromTicket() {
      this.ticketForm = {
        ...this.defaultTicket,
        ...cloneDeep(this.selectedTicket),
        eventId: this.eventId,
      };
      if (this.ticketForm.ticketUserProfile.profileItems == null) {
        this.ticketForm.ticketUserProfile.profileItems = [];
      }
    },
    goBackToList() {
      this.$emit('closeDialog');
    },
    next() {
      this.stepper = 2;
    },
    async save() {
      await this[SAVE_TICKET](this.ticketForm);
      await this.goBackToList();
    },
  },
  async mounted() {
    await this[GET_ALL_ASSEMBLIES](this.eventId);
    await this[GET_ADMIN_TICKETS](this.eventId);

    if (this.ticketId) {
      const currentTicket = this.tickets.find((ticket) => ticket.id === this.ticketId);
      await this[SELECT_TICKET](currentTicket);
    }

    this.initFormFromTicket();
    this.isLoaded = true;
  },
  async beforeDestroy() {
    this[CLEAR_SELECTED_TICKET]();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();
</style>
