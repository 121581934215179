<template>
  <v-container id="tickets">
    <h2 class="tickets-list__title">
      {{ $t('admin.tickets.title', { eventName: event.name }) }}
    </h2>

    <div class="tickets-list__actions">
      <v-btn
        large
        class="actions__create"
        color="primary"
        @click="addTicket()"
        :disabled="!canCreateTickets"
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </div>

    <base-list
      class="mt-4"
      backgroundColor="var(--v-adminBackground-base)"
      :headers="headers"
      :headerProps="{ sortIcon: null }"
      :items="tickets"
      :itemsPerPage="10"
      :loading="!isLoaded"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template class="centered" v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template class="centered" v-slot:[`item.restricted`]="{ item }">
        <v-icon class="icon icon__restricted" v-if="item.restricted"> mdi-lock-outline </v-icon>

        <v-icon class="icon" v-else>mdi-lock-open-outline</v-icon>
      </template>

      <template class="centered" v-slot:[`item.conferences`]="{ item }">
        <v-icon class="icon" v-if="allConferencesAreAuthorized(item)"> mdi-account-voice </v-icon>

        <v-icon class="icon icon__some" v-else-if="someConferencesAreAuthorize(item)">
          mdi-account-voice
        </v-icon>

        <v-icon class="icon icon__none" v-else>mdi-account-voice</v-icon>
      </template>

      <template class="centered" v-slot:[`item.discussionGroups`]="{ item }">
        <v-icon class="icon" v-if="allDiscussionGroupsAreAuthorized(item)"> mdi-bullhorn </v-icon>

        <v-icon class="icon icon__some" v-else-if="someDiscussionGroupsAreAuthorize(item)">
          mdi-bullhorn
        </v-icon>

        <v-icon class="icon icon__none" v-else>mdi-bullhorn</v-icon>
      </template>

      <template class="centered" v-slot:[`item.showroom`]="{ item }">
        <v-icon class="icon" v-if="isShowroomAuthorized(item)"> mdi-briefcase-account </v-icon>

        <v-icon class="icon icon__restricted" v-else>mdi-briefcase-account</v-icon>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <base-price :amount="item.price" :currency="item.currency" />
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <span>{{ $t(`admin.tickets.type.${item.type}`) }}</span>
      </template>

      <template v-slot:[`item.roles`]="{ item }">
        <span v-for="(role, index) in computedRoles(item)" :key="role">
          {{ $t(`admin.tickets.type.${role}`) }}
          <span v-if="index !== computedRoles(item).length - 1"> / </span>
        </span>
        <span v-if="computedRoles(item).length === 0"> - </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="currentLoggedInUser"
          @edit-item="editTicket(item)"
          :hasDelete="false"
          :canEdit="canUpdateTicket"
        />
      </template>
    </base-list>

    <v-dialog
      content-class="v-dialog--overlay"
      v-model="dialog.ticket"
      v-if="dialog.ticket"
      width="800"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>
          {{ event.name }} -
          {{ $t(`admin.tickets.editModal.title.${modalTitle}`) }}
        </v-card-title>

        <v-card-text>
          <edit-ticket-admin
            :type="modalTitle"
            :eventId="eventId"
            :ticketId="selectedTicketId"
            @closeDialog="closeDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BasePrice from '@/components/base-price/BasePrice.vue';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  ADMIN_TICKET_MODULE,
  CREATE_TICKET,
  GET_ADMIN_TICKETS,
  UPDATE_TICKET,
} from '@/stores/umanize-admin/actions/ticket/admin-ticket.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import BaseList from '@/components/base-list/BaseList.vue';
import EditTicketAdmin from './edit/EditTicketAdmin.vue';

export default {
  name: 'TicketsAdmin',
  components: {
    AdminTableActions,
    BasePrice,
    BaseList,
    EditTicketAdmin,
  },
  data: () => ({
    dialog: {
      ticket: false,
    },
    modalTitle: 'edit',
    selectedTicketId: null,
  }),
  computed: {
    ...mapGetters(ADMIN_TICKET_MODULE, ['tickets', 'isLoaded']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    headers() {
      return [
        {
          text: this.$t('admin.tickets.headers.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('admin.tickets.headers.price'),
          align: 'center',
          value: 'price',
          width: '100px',
        },
        {
          text: this.$t('admin.tickets.headers.restricted'),
          align: 'center',
          value: 'restricted',
          width: '100px',
        },
        {
          text: this.$t('admin.tickets.headers.conferences'),
          align: 'center',
          value: 'conferences',
        },
        {
          text: this.$t('admin.tickets.headers.discussionGroups'),
          align: 'center',
          value: 'discussionGroups',
        },
        {
          text: this.$t('admin.tickets.headers.showroom'),
          align: 'center',
          value: 'showroom',
        },
        {
          text: this.$t('admin.tickets.headers.type'),
          align: 'center',
          value: 'type',
        },
        {
          text: this.$t('admin.tickets.headers.roles'),
          align: 'center',
          value: 'roles',
        },
        {
          value: 'actions',
          align: 'center',
          width: '75px',
        },
      ];
    },
    eventId() {
      return this.$route.params.eventId;
    },
    canUpdateTicket() {
      return PermissionsUtil.isAuthorized(
        ['permission.tickets.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canCreateTickets() {
      return PermissionsUtil.isAuthorized(
        ['permission.tickets.canCreate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_TICKET_MODULE, [GET_ADMIN_TICKETS, UPDATE_TICKET, CREATE_TICKET]),
    computedRoles(item) {
      return item.roles.filter((role) => role !== item.type);
    },
    allConferencesAreAuthorized(item) {
      return item?.options?.features?.conferences && item?.options?.conferences.length === 0;
    },
    someConferencesAreAuthorize(item) {
      return item?.options?.features?.conferences && item?.options?.conferences.length !== 0;
    },
    allDiscussionGroupsAreAuthorized(item) {
      return (
        item?.options?.features?.discussionGroups && item?.options?.discussionGroups.length === 0
      );
    },
    someDiscussionGroupsAreAuthorize(item) {
      return (
        item?.options?.features?.discussionGroups && item?.options?.discussionGroups.length !== 0
      );
    },
    isShowroomAuthorized(item) {
      return item?.options?.features?.showroom;
    },
    editTicket(item) {
      this.selectedTicketId = item.id;
      this.modalTitle = 'edit';
      this.dialog.ticket = true;
    },
    addTicket() {
      this.modalTitle = 'create';
      this.dialog.ticket = true;
    },
    closeDialog() {
      this.selectedTicketId = null;
      this.dialog.ticket = false;
    },
  },
  mounted() {
    this[GET_ADMIN_TICKETS](this.$route.params.eventId);
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

#tickets {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.tickets-list {
  &__title {
    margin-bottom: 10px;
  }
}

.centered {
  text-align: center;
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }

  &__some {
    color: var(--v-warning-base);
  }

  &__none {
    color: var(--v-error-base);
  }
}
</style>
