<template>
  <v-container id="discussion-groups">
    <h2 class="discussions-groups__title">
      {{ event.name }} - {{ $t('admin.discussionGroups.title') }}
    </h2>

    <div class="discussions-groups__actions">
      <v-btn
        large
        depressed
        class="actions__create"
        color="primary"
        @click="addDiscussionGroup()"
        :disabled="!canCreate"
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <discussion-groups-table
      :discussion-groups="discussionGroups"
      :discussion-groups-with-partners="discussionGroupsWithPartners"
      :conferences-are-loading="discussionGroupsAreLoading"
      :search="search"
      :user="currentLoggedInUser"
      :can-edit="canUpdate"
      :can-delete="canDelete"
      @edit-discussion-group="editDiscussionGroup"
      @delete-discussion-group="deleteDiscussionGroup"
    />

    <dialog-confirmation
      :visible="showConfirmation"
      :title="$t('admin.discussionGroups.delete.deleteConfirmationDialog.title')"
      :content="$t('admin.discussionGroups.delete.deleteConfirmationDialog.content')"
      @cancel="confirmationDialogCancel"
      @confirm="confirmationDialogConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DialogConfirmation from '@/views/DialogConfirmation.vue';
import DiscussionGroupsTable from '@/components/admin/discussion-groups-table/DiscussionGroupsTable.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADMIN_DISCUSSION_GROUP_MODULE,
  DELETE_DISCUSSION_GROUP,
} from '@/stores/umanize-admin/actions/discussion-group/admin-discussion-group.actions';
import { GET_DISCUSSION_GROUPS } from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  GET_PARTNERS,
  PARTNER_MODULE,
} from '@/stores/umanize-admin/actions/partners/partners.actions';

const NAME_SIZE_LIMIT = 100;
const DESCRIPTION_SIZE_LIMIT = 120;

export default {
  name: 'DiscussionGroupsAdmin',
  components: {
    DiscussionGroupsTable,
    DialogConfirmation,
    AppSearch,
  },
  data: () => ({
    search: '',
    showConfirmation: false,
    discussionGroupToDelete: null,
    discussionGroupsWithPartners: [],
  }),
  computed: {
    ...mapGetters(ADMIN_DISCUSSION_GROUP_MODULE, [
      'discussionGroups',
      'discussionGroupIsSaving',
      'discussionGroupsAreLoading',
    ]),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    ...mapGetters(PARTNER_MODULE, ['partners']),
    eventId() {
      return this.$route.params?.eventId;
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.discussionGroups.canUpdatePartial'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.discussionGroups.canCreate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.discussionGroups.canDelete'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_DISCUSSION_GROUP_MODULE, [GET_DISCUSSION_GROUPS, DELETE_DISCUSSION_GROUP]),
    ...mapActions(PARTNER_MODULE, [GET_PARTNERS]),
    onSearchChange(search) {
      this.search = search;
    },
    discussionGroupDescription(description) {
      return description.length > DESCRIPTION_SIZE_LIMIT
        ? `${description.slice(0, DESCRIPTION_SIZE_LIMIT)} ...`
        : description;
    },
    discussionGroupName(name) {
      return name.length > NAME_SIZE_LIMIT ? `${name.slice(0, NAME_SIZE_LIMIT)} ...` : name;
    },
    async loadDiscussionGroups() {
      await this[GET_DISCUSSION_GROUPS](this.eventId);
      this.discussionGroups.forEach((group) => this.retrievePartners(group));
    },
    async retrievePartners(discussionGroup) {
      await this[GET_PARTNERS]({
        eventId: this.eventId,
        discussionGroupId: discussionGroup.id,
      });
      if (this.partners.length) {
        this.discussionGroupsWithPartners.push(discussionGroup.id);
      }
    },
    addDiscussionGroup() {
      const { eventId } = this.$route.params;
      this.$router.push({
        name: 'AddDiscussionGroupAdmin',
        params: {
          eventId,
        },
      });
    },
    editDiscussionGroup(discussionGroup) {
      const { eventId } = this.$route.params;
      this.$router.push({
        name: 'EditDiscussionGroupAdmin',
        params: {
          eventId,
          discussionGroupId: discussionGroup.id,
        },
      });
    },
    async deleteDiscussionGroup(item) {
      this.discussionGroupToDelete = { ...item };
      this.showConfirmation = true;
    },
    confirmationDialogCancel() {
      this.showConfirmation = false;
      this.discussionGroupToDelete = null;
    },
    async confirmationDialogConfirm() {
      const { eventId, id } = this.discussionGroupToDelete;
      await this[DELETE_DISCUSSION_GROUP]({
        eventId,
        discussionGroupId: id,
      });
      this.loadDiscussionGroups();
      this.discussionGroupToDelete = null;
      this.showConfirmation = false;
    },
  },
  mounted() {
    this.loadDiscussionGroups();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

#discussion-groups {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}

.discussions-groups {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
