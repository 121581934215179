<template>
  <div id="editProfil1" @submit.prevent @submit="save">
    <v-form v-model="valid" :disabled="!canUpdateTickets">
      <v-col>
        <v-row>
          <v-col cols="12" sm="6">
            <v-radio-group v-model="ticketFormLocal.ticketUserProfile.displayAvatar">
              <template v-slot:label>
                <div class="editTicketAdmin__label">
                  {{ $t('admin.tickets.titles.displayAvatar') }}
                </div>
              </template>

              <div class="editTicketAdmin__restricted">
                <v-radio
                  v-for="item in authorizeDisplayAvatar"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </div>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-alert v-if="!canEditProfile" type="warning">
          {{ $t('admin.tickets.profileItems.unableEditableError') }}
        </v-alert>

        <div>
          {{ $t('admin.tickets.profileItems.description') }}
        </div>

        <v-btn
          class="m-4"
          color="primary"
          depressed
          large
          @click="addProfileItem()"
          :disabled="!canEditProfile"
        >
          {{ $t('admin.tickets.profileItems.addField') }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>

      <div
        v-for="(item, index) in ticketFormLocal.ticketUserProfile.profileItems"
        :key="`profileItems-${index}`"
        :class="getClass(index)"
      >
        <v-row>
          <v-col cols="12" sm="5">
            <v-text-field
              :label="$t('admin.tickets.profileItems.fr')"
              :persistent-placeholder="true"
              placeholder="-"
              hide-details="auto"
              v-model="item.name.fr"
              :disabled="item.disabled || !canEditProfile"
              :rules="rules.nameFr"
            />
          </v-col>

          <v-col cols="12" sm="5">
            <v-text-field
              :label="$t('admin.tickets.profileItems.en')"
              :persistent-placeholder="true"
              v-model="item.name.en"
              :disabled="item.disabled || !canEditProfile"
              placeholder="-"
              hide-details="auto"
              :rules="rules.nameEn"
            />
          </v-col>

          <v-col v-if="!item.disabled" cols="12" sm="2">
            <v-btn
              color="primary"
              data-test-id="delete"
              icon
              @click="removeProfileItem(index)"
              :disabled="!canEditProfile"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-checkbox
          v-model="item.required"
          :disabled="item.disabled || !canEditProfile"
          :label="$t('admin.tickets.profileItems.required')"
        ></v-checkbox>

        <v-row>
          <v-col cols="12" sm="5">
            <v-select
              @input="changedSelectType(index)"
              v-model="item.type"
              class="form-row__item-right"
              :items="profileItemsTypes"
              :rules="rules.role"
              name="profileItemsTypeDefault"
              :placeholder="$t('admin.tickets.profileItems.selectOne')"
              item-text="name"
              :label="$t('admin.tickets.profileItems.type')"
              :disabled="item.disabled || !canEditProfile"
            />
          </v-col>
        </v-row>

        <v-col cols="12" sm="12" v-if="ProfileItemTypeWithValues.includes(item.type)">
          <div>
            <div
              v-for="(value, valueIndex) in item.values"
              :key="`values-id-key-${index}-${valueIndex}`"
            >
              <v-row>
                <v-col cols="5" sm="5">
                  <v-text-field
                    v-model="value.value.fr"
                    :persistent-placeholder="true"
                    :label="$t('admin.tickets.profileItems.fr')"
                    placeholder="-"
                    hide-details="auto"
                    :rules="rules.valueFr"
                    :disabled="!canEditProfile"
                  />
                </v-col>

                <v-col cols="5" sm="5">
                  <v-text-field
                    v-model="value.value.en"
                    :persistent-placeholder="true"
                    :label="$t('admin.tickets.profileItems.en')"
                    placeholder="-"
                    hide-details="auto"
                    :rules="rules.valueEn"
                    :disabled="!canEditProfile"
                  />
                </v-col>

                <v-col cols="2" sm="2">
                  <v-btn
                    color="primary"
                    data-test-id="delete"
                    icon
                    :disabled="!canEditProfile"
                    @click="removeValues(index, valueIndex, answer)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12" sm="12">
                <div>
                  <v-btn
                    style="width: auto"
                    color="primary"
                    icon
                    @click="addValues(index)"
                    :disabled="!canEditProfile"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ $t('admin.tickets.profileItems.addValue') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </div>

      <v-card-actions class="actions">
        <v-btn outlined color="primary" @click="goBackToList">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn color="primary" type="submit" :disabled="!valid || !canUpdateTickets">
          {{ $t('globals.save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { ProfileItemType, ProfileItemTypeWithValues } from '@/models/ticketing/profile-item.model';

import VALIDATORS from '@/helpers/forms/validators.helper';

export default {
  name: 'EditTicketProfileForm',
  props: {
    canUpdateTickets: {
      type: Boolean,
    },
    ticketForm: {
      type: Object,
    },
    isCreating: {
      type: Boolean,
    },
  },
  data: () => ({
    rules: {
      nameFr: VALIDATORS.TICKETS.NAME_FR,
      nameEn: VALIDATORS.TICKETS.NAME_EN,
      valueFr: VALIDATORS.TICKETS.VALUE_FR,
      valueEn: VALIDATORS.TICKETS.VALUE_EN,
      type: VALIDATORS.TICKETS.TYPE,
    },
    valid: false,
    stepper: 1,
    ProfileItemTypeWithValues,
    profileItemsTypeDefault: ProfileItemType.text,
  }),
  computed: {
    ticketFormLocal: {
      get() {
        return this.ticketForm;
      },
      set(value) {
        this.$emit('ticketForm', value);
      },
    },
    profileItemsTypes() {
      return [
        {
          name: this.$t('admin.tickets.profileItems.text'),
          value: ProfileItemType.text,
        },
        {
          name: this.$t('admin.tickets.profileItems.number'),
          value: ProfileItemType.number,
        },
        {
          name: this.$t('admin.tickets.profileItems.textarea'),
          value: ProfileItemType.textarea,
        },
        {
          name: this.$t('admin.tickets.profileItems.listbox'),
          value: ProfileItemType.listbox,
        },
        {
          name: this.$t('admin.tickets.profileItems.listboxMultiple'),
          value: ProfileItemType.listboxMultiple,
        },
        {
          name: this.$t('admin.tickets.profileItems.radio'),
          value: ProfileItemType.radio,
        },
        {
          name: this.$t('admin.tickets.profileItems.checkbox'),
          value: ProfileItemType.checkbox,
        },
        {
          name: this.$t('admin.tickets.profileItems.date'),
          value: ProfileItemType.date,
        },
        {
          name: this.$t('admin.tickets.profileItems.phone'),
          value: ProfileItemType.phone,
        },
        {
          name: this.$t('admin.tickets.profileItems.email'),
          value: ProfileItemType.email,
        },
      ];
    },
    authorizeDisplayAvatar() {
      return [
        {
          label: this.$t('admin.tickets.displayAvatar.yes'),
          value: true,
        },
        {
          label: this.$t('admin.tickets.displayAvatar.no'),
          value: false,
        },
      ];
    },
    restrictionItems() {
      return [
        {
          label: this.$t('admin.tickets.restricted.no'),
          value: false,
        },
        {
          label: this.$t('admin.tickets.restricted.yes'),
          value: true,
        },
      ];
    },
    canEditProfile() {
      return this.isCreating
        ? true
        : this.ticketFormLocal?.ticketUserProfile?.status?.isEditable &&
            !this.ticketFormLocal?.ticketUserProfile?.defaultProfile;
    },
  },
  methods: {
    save() {
      this.$emit('save');
    },
    goBackToList() {
      this.$emit('close');
    },
    removeProfileItem(index) {
      this.ticketFormLocal.ticketUserProfile.profileItems.splice(index, 1);
    },
    addProfileItem() {
      this.ticketFormLocal.ticketUserProfile.profileItems.push({
        type: 0,
        name: { fr: '', en: '' },
        values: [],
        order: 0,
        required: false,
        deleted: false,
        isNew: true,
      });
    },
    addValues(index) {
      this.ticketFormLocal.ticketUserProfile.profileItems[index].values.push({
        value: { fr: '', en: '' },
      });
    },
    removeValues(index, answerIndex) {
      this.ticketFormLocal.ticketUserProfile.profileItems[index].values.splice(answerIndex, 1);
    },
    changedSelectType(index) {
      this.ticketFormLocal.ticketUserProfile.profileItems[index].values = [];
    },
    getClass(index) {
      if (index % 2 === 0) {
        return 'rounded-bg';
      }
      return 'rounded-border';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.rounded-bg {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
}

.rounded-border {
  padding: 10px;
}
</style>
