<template>
  <v-container id="exhibitors">
    <h2 class="exhibitors__title">{{ event.name }} - {{ $t('admin.exhibitors.title') }}</h2>

    <div class="exhibitors__actions">
      <v-btn
        class="actions__create"
        large
        depressed
        color="primary"
        @click="addExhibitor()"
        :disabled="!canCreate"
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <base-list
      class="mt-4"
      backgroundColor="var(--v-adminBackground-base)"
      :headers="headers"
      :items="exhibitorsWithCompletion"
      :search="search"
      :footer-props="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
      }"
      :no-data-text="$t('globals.datatable.noData')"
      :no-results-text="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.tags`]="{ item }">
        <div v-if="item.kioskOptions.tags">
          <v-chip class="mr-2" v-for="tag in item.kioskOptions.tags.split(',')" :key="tag">
            {{ tag }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="currentLoggedInUser"
          @edit-item="editExhibitor(item)"
          @delete-item="confirmDeleteExhibitor(item)"
          :is-disabled="hasRepresent(item)"
          :can-edit="canUpdate"
          :can-delete="canDelete"
          :disabled-tooltip="$t('exhibitors.delete.hasContent')"
        />
      </template>
    </base-list>

    <dialog-confirmation
      :v-if="exhibitorToDelete"
      :visible="showConfirmation"
      :title="$t('exhibitors.delete.deleteConfirmationDialog.title')"
      :content="$t('exhibitors.delete.deleteConfirmationDialog.content')"
      @cancel="confirmationDialogCancel"
      @confirm="confirmationDialogConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DialogConfirmation from '@/views/DialogConfirmation.vue';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  GET_EXHIBITORS,
  DELETE_EXHIBITOR,
  CLEAR_EXHIBITOR,
} from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { ADMIN_EXHIBITOR_MODULE } from '@/stores/umanize-admin/actions/exhibitor/exhibitor.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';

export default {
  name: 'ExhibitorsAdmin',
  components: {
    AdminTableActions,
    DialogConfirmation,
    BaseList,
    AppSearch,
  },
  data: () => ({
    search: '',
    exhibitorToDelete: null,
    showConfirmation: false,
  }),
  computed: {
    ...mapGetters(ADMIN_EXHIBITOR_MODULE, ['exhibitorsWithCompletion', 'exhibitorsAreLoading']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    headers() {
      return [
        {
          text: this.$t('admin.exhibitors.headers.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('admin.exhibitors.headers.priority'),
          value: 'priority',
          sortable: true,
        },
        {
          text: this.$t('admin.exhibitors.headers.completion'),
          value: 'completion',
          sortable: false,
        },
        {
          text: this.$t('admin.exhibitors.headers.tags'),
          value: 'tags',
          sortable: false,
        },
        {
          value: 'actions',
          width: '100px',
        },
      ];
    },
    eventId() {
      return this.$route.params.eventId;
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.exhibitors.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.exhibitors.canCreate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.exhibitors.canDelete'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_EXHIBITOR_MODULE, [GET_EXHIBITORS, CLEAR_EXHIBITOR, DELETE_EXHIBITOR]),
    onSearchChange(search) {
      this.search = search;
    },
    editExhibitor(item) {
      this.$router.push({
        name: 'EditExhibitorAdmin',
        params: {
          eventId: this.eventId,
          exhibitorId: item.id,
        },
      });
    },
    confirmDeleteExhibitor(item) {
      this.exhibitorToDelete = item;
      this.showConfirmation = true;
    },
    confirmationDialogCancel() {
      this.exhibitorToDelete = null;
      this.showConfirmation = false;
    },
    async confirmationDialogConfirm() {
      await this.deleteExhibitor(this.exhibitorToDelete);
      this.exhibitorToDelete = null;
      this.showConfirmation = false;
    },
    async deleteExhibitor(exhibitor) {
      await this[DELETE_EXHIBITOR]({
        eventId: this.eventId,
        exhibitorId: exhibitor.id,
      });
    },
    hasRepresent(exhibitor) {
      return exhibitor.users.length > 0;
    },
    addExhibitor() {
      this.$router.push({
        name: 'AddExhibitorAdmin',
        params: {
          eventId: this.eventId,
        },
      });
    },
  },
  async mounted() {
    await this[GET_EXHIBITORS]({ eventId: this.eventId });
  },
  beforeDestroy() {
    this[CLEAR_EXHIBITOR]();
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

#exhibitors {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.exhibitors {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
