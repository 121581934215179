<template>
  <div id="editTicketForm" @submit.prevent @submit="next">
    <v-form v-model="valid" :disabled="!canUpdateTickets">
      <v-row>
        <v-col cols="12">
          <v-text-field
            class="full"
            :label="$t('admin.tickets.headers.name')"
            v-model="ticketFormLocal.name"
            :rules="rules.name"
            :persistent-placeholder="true"
            placeholder="-"
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            class="full"
            :label="$t('admin.tickets.headers.type')"
            :items="ticketTypeItems"
            item-text="label"
            name="value"
            v-model="ticketFormLocal.type"
            :rules="rules.role"
            hide-details="auto"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            class="full"
            :label="roleSelectLabel"
            :items="displayedTicketTypeItems"
            multiple
            item-text="label"
            name="value"
            v-model="ticketFormLocal.roles"
            :disabled="!ticketFormLocal.type"
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            class="full"
            :label="$t('admin.tickets.headers.description')"
            :rows="3"
            v-model="ticketFormLocal.description"
            :rules="rules.description"
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-radio-group v-model="ticketFormLocal.restricted">
            <template v-slot:label>
              <div class="editTicketAdmin__label">
                {{ $t('admin.tickets.titles.restricted') }}
              </div>
            </template>

            <div class="editTicketAdmin__restricted">
              <v-radio
                v-for="item in restrictionItems"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </div>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>{{ $t('admin.tickets.titles.price') }}</h2>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            type="number"
            :label="$t('admin.tickets.headers.price')"
            v-model.number="ticketFormLocal.price"
            :rules="rules.price"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="ticketFormLocal.currency"
            :items="currencyList"
            item-value="Code"
            item-text="Code"
            :label="$t('admin.tickets.headers.currency')"
            :rules="rules.currency"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.Code }} - {{ data.item.Currency }}
            </template>

            <template slot="item" slot-scope="data">
              {{ data.item.Code }} - {{ data.item.Currency }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>{{ $t('admin.tickets.titles.access') }}</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-checkbox
            v-model="ticketFormLocal.options.features.conferences"
            :label="$t('admin.tickets.conferences.authorized')"
            hide-details
          />

          <v-select
            multiple
            v-model="selectedConferences"
            :items="conferences"
            item-text="name"
            item-value="id"
            hide-details="auto"
            :loading="conferencesAreLoading"
            :disabled="!ticketFormLocal.options.features.conferences"
            :no-data-text="$t('globals.datatable.noData')"
          >
            <template v-slot:prepend-item v-if="!!conferences.length">
              <v-list-item ripple @click="selectAllConferences">
                <v-list-item-action>
                  <v-icon :color="selectedConferences.length > 0 ? 'primary' : ''">
                    {{ conferencesIcon }}
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('globals.selectAll') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>

            <template v-slot:selection="{ index }">
              <span v-if="index === 0 && selectedConferences.length !== conferences.length">
                {{ $tc('admin.tickets.selectedConferences', selectedConferences.length) }}
              </span>
              <span v-if="index === 0 && selectedConferences.length === conferences.length">
                {{ $tc('admin.tickets.selectedAllConferences', selectedConferences.length) }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-checkbox
            v-model="ticketFormLocal.options.features.discussionGroups"
            :label="$t('admin.tickets.discussionGroups.authorized')"
            hide-details
          />

          <v-select
            multiple
            v-model="selectedDiscussionGroups"
            :items="discussionGroups"
            item-text="name"
            item-value="id"
            hide-details="auto"
            :loading="discussionGroupsAreLoading"
            :disabled="!ticketFormLocal.options.features.discussionGroups"
            :no-data-text="$t('globals.datatable.noData')"
          >
            <template v-slot:prepend-item v-if="!!discussionGroups.length">
              <v-list-item ripple @click="selectAllDiscussionGroups">
                <v-list-item-action>
                  <v-icon :color="selectedDiscussionGroups.length > 0 ? 'primary' : ''">
                    {{ discussionGroupsIcon }}
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('globals.selectAll') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mt-2"></v-divider>
            </template>

            <template v-slot:selection="{ index }">
              <span
                v-if="index === 0 && selectedDiscussionGroups.length !== discussionGroups.length"
              >
                {{ $tc('admin.tickets.selectedDiscussionGroups', selectedDiscussionGroups.length) }}
              </span>

              <span
                v-if="index === 0 && selectedDiscussionGroups.length === discussionGroups.length"
              >
                {{ $tc('admin.tickets.selectedAllDiscussionGroups') }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="ticketFormLocal.options.features.showroom"
            :label="$t('admin.tickets.showroom.authorized')"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="ticketFormLocal.options.features.generalAssemblies"
            :label="$t('admin.tickets.generalAssembly.authorized')"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-checkbox
            v-model="ticketFormLocal.options.features.contentLibrary"
            :label="$t('admin.tickets.contentCategories.authorized')"
            hide-details
          />

          <v-select
            multiple
            v-model="selectedContentCategories"
            :items="contentCategories"
            item-text="name"
            item-value="id"
            hide-details="auto"
            :loading="categoriesAreLoading"
            :disabled="!ticketFormLocal.options.features.contentLibrary"
            :no-data-text="$t('globals.datatable.noData')"
          >
            <template v-slot:prepend-item v-if="!!contentCategories.length">
              <v-list-item ripple @click="selectAllContentCategories">
                <v-list-item-action>
                  <v-icon :color="selectedContentCategories.length > 0 ? 'primary' : ''">
                    {{ contentIcon }}
                  </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('globals.selectAll') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mt-2"></v-divider>
            </template>

            <template v-slot:selection="{ index }">
              <span
                v-if="index === 0 && selectedContentCategories.length !== contentCategories.length"
              >
                {{
                  $tc('admin.tickets.selectedContentCategories', selectedContentCategories.length)
                }}
              </span>

              <span
                v-if="index === 0 && selectedContentCategories.length === contentCategories.length"
              >
                {{ $tc('admin.tickets.selectedAllContentCategories') }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="gaAccessHeaders"
            :items="gaAccess"
            :footer-props="{
              itemsPerPageText: $t('globals.datatable.itemPerPage'),
              itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
            }"
            :no-data-text="$t('globals.datatable.noData')"
            :no-results-text="$t('globals.datatable.noResult')"
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>

            <template v-slot:[`item.canAccess`]="{ item }">
              <v-simple-checkbox
                :value="item.canAccess"
                @click="gaAccessChange(item)"
                :disabled="!ticketFormLocal.options.features.generalAssemblies"
              />
            </template>

            <template v-slot:[`item.canVote`]="{ item }">
              <v-simple-checkbox
                v-model="item.canVote"
                :disabled="!ticketFormLocal.options.features.generalAssemblies || !item.canAccess"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-card-actions class="actions">
        <v-btn outlined color="primary" @click="goBackToList">
          {{ $t('globals.cancel') }}
        </v-btn>
        <v-btn color="primary" type="submit" :disabled="!valid || !canUpdateTickets">
          {{ $t('globals.continue') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import currencies from 'iso4217';
import { mapActions, mapGetters } from 'vuex';
import { ProfileItemType, ProfileItemTypeWithValues } from '@/models/ticketing/profile-item.model';

import { TicketType } from '@/models/ticketing/ticket-type.model';

import DataUtil from '@/helpers/data/data.helper';
import VALIDATORS from '@/helpers/forms/validators.helper';

import { ADMIN_TICKET_MODULE } from '@/stores/umanize-admin/actions/ticket/admin-ticket.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { ADMIN_CONFERENCE_MODULE } from '@/stores/umanize-admin/actions/conference/admin-conference.actions';
import { ADMIN_ASSEMBLY_MODULE } from '@/stores/umanize-admin/actions/general-assembly/general-assembly.actions';
import { ADMIN_DISCUSSION_GROUP_MODULE } from '@/stores/umanize-admin/actions/discussion-group/admin-discussion-group.actions';
import { ADMIN_CONTENT_MODULE } from '@/stores/umanize-admin/actions/content/admin-content.actions';

import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import { toTicketGAOptionsWithName } from '@/views/admin/tickets/edit/edit-ticket-admin.helper';

import { GET_CONFERENCES_FOR_EVENT } from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import { GET_DISCUSSION_GROUPS } from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';
import { GET_CONTENT_CATEGORIES } from '@/stores/agnostic/actions/content/agnostic-content.actions';

export default {
  name: 'EditTicketForm',
  props: {
    eventId: {
      type: String,
    },
    canUpdateTickets: {
      type: Boolean,
    },
    ticketForm: {
      type: Object,
    },
  },
  data: () => ({
    selectedConferences: [],
    selectedDiscussionGroups: [],
    selectedContentCategories: [],
    tab: 'ticketForm',
    rules: {
      name: VALIDATORS.TICKETS.NAME,
      description: VALIDATORS.TICKETS.DESCRIPTION,
      role: VALIDATORS.TICKETS.ROLE,
      price: VALIDATORS.TICKETS.PRICE,
      currency: VALIDATORS.TICKETS.CURRENCY,
    },
    valid: false,
    gaAccess: [],
    ProfileItemTypeWithValues,
    profileItemsTypeDefault: ProfileItemType.text,
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(ADMIN_TICKET_MODULE, ['tickets', 'selectedTicket', 'isLoaded']),
    ...mapGetters(ADMIN_CONFERENCE_MODULE, ['conferences', 'conferencesAreLoading']),
    ...mapGetters(ADMIN_DISCUSSION_GROUP_MODULE, [
      'discussionGroups',
      'discussionGroupsAreLoading',
    ]),
    ...mapGetters(ADMIN_CONTENT_MODULE, ['contentCategories', 'categoriesAreLoading']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(ADMIN_ASSEMBLY_MODULE, ['generalAssemblies']),
    ticketFormLocal: {
      get() {
        return this.ticketForm;
      },
      set(value) {
        this.$emit('ticketForm', value);
      },
    },
    profileItemsTypes() {
      return [
        {
          name: this.$t('admin.tickets.profileItems.text'),
          value: ProfileItemType.text,
        },
        {
          name: this.$t('admin.tickets.profileItems.number'),
          value: ProfileItemType.number,
        },
        {
          name: this.$t('admin.tickets.profileItems.textarea'),
          value: ProfileItemType.textarea,
        },
        {
          name: this.$t('admin.tickets.profileItems.listbox'),
          value: ProfileItemType.listbox,
        },
        {
          name: this.$t('admin.tickets.profileItems.listboxMultiple'),
          value: ProfileItemType.listboxMultiple,
        },
        {
          name: this.$t('admin.tickets.profileItems.radio'),
          value: ProfileItemType.radio,
        },
        {
          name: this.$t('admin.tickets.profileItems.checkbox'),
          value: ProfileItemType.checkbox,
        },
        {
          name: this.$t('admin.tickets.profileItems.date'),
          value: ProfileItemType.date,
        },
        {
          name: this.$t('admin.tickets.profileItems.phone'),
          value: ProfileItemType.phone,
        },
        {
          name: this.$t('admin.tickets.profileItems.email'),
          value: ProfileItemType.email,
        },
      ];
    },
    authorizeDisplayAvatar() {
      return [
        {
          label: this.$t('admin.tickets.displayAvatar.yes'),
          value: true,
        },
        {
          label: this.$t('admin.tickets.displayAvatar.no'),
          value: false,
        },
      ];
    },
    restrictionItems() {
      return [
        {
          label: this.$t('admin.tickets.restricted.no'),
          value: false,
        },
        {
          label: this.$t('admin.tickets.restricted.yes'),
          value: true,
        },
      ];
    },
    ticketTypeItems() {
      return [
        {
          label: this.$t('admin.tickets.type.visitor'),
          value: TicketType.visitor,
        },
        {
          label: this.$t('admin.tickets.type.exhibitor'),
          value: TicketType.exhibitor,
        },
        {
          label: this.$t('admin.tickets.type.organizer'),
          value: TicketType.organizer,
        },
        {
          label: this.$t('admin.tickets.type.speaker'),
          value: TicketType.speaker,
        },
      ];
    },
    gaAccessHeaders() {
      return [
        {
          text: this.$t('admin.tickets.generalAssembly.name'),
          value: 'name',
        },
        {
          text: this.$t('admin.tickets.generalAssembly.canAccess'),
          value: 'canAccess',
          align: 'center',
          width: '150px',
        },
        {
          text: this.$t('admin.tickets.generalAssembly.canVote'),
          value: 'canVote',
          align: 'center',
          width: '150px',
        },
      ];
    },
    currencyList() {
      return Object.values(currencies);
    },
    displayedTicketTypeItems() {
      // Remove the current type from the roles so that user is not confused
      return DataUtil.removeItemFromObjectArray(
        this.ticketTypeItems,
        'value',
        this.ticketFormLocal.type,
      );
    },
    roleSelectLabel() {
      return `${this.$t('admin.tickets.headers.roles')} (${this.$t('globals.optional')})`;
    },
    selectedAllConferences() {
      return this.selectedConferences.length === this.conferences.length;
    },
    selectedSomeConferences() {
      return this.selectedConferences.length > 0 && !this.selectedAllConferences;
    },
    conferencesIcon() {
      if (this.selectedAllConferences) return 'mdi-close-box';
      if (this.selectedSomeConferences) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    selectedAllDiscussionGroups() {
      return this.selectedDiscussionGroups.length === this.discussionGroups.length;
    },
    selectedSomeDiscussionGroups() {
      return this.selectedDiscussionGroups.length > 0 && !this.selectedAllDiscussionGroups;
    },
    discussionGroupsIcon() {
      if (this.selectedAllDiscussionGroups) return 'mdi-close-box';
      if (this.selectedSomeDiscussionGroups) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    selectedAllContentCategories() {
      return this.selectedContentCategories.length === this.contentCategories.length;
    },
    selectedSomeContentCategories() {
      return this.selectedContentCategories.length > 0 && !this.selectedAllContentCategories;
    },
    contentIcon() {
      if (this.selectedAllContentCategories) return 'mdi-close-box';
      if (this.selectedSomeContentCategories) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    ...mapActions(ADMIN_CONFERENCE_MODULE, [GET_CONFERENCES_FOR_EVENT]),
    ...mapActions(ADMIN_DISCUSSION_GROUP_MODULE, [GET_DISCUSSION_GROUPS]),
    ...mapActions(ADMIN_CONTENT_MODULE, [GET_CONTENT_CATEGORIES]),
    save() {
      this.$emit('save');
    },
    goBackToList() {
      this.$emit('close');
    },
    addValues(index) {
      this.ticketFormLocal.ticketUserProfile.profileItems[index].values.push({
        value: { fr: '', en: '' },
      });
    },
    removeValues(index, answerIndex) {
      this.ticketFormLocal.ticketUserProfile.profileItems[index].values.splice(answerIndex, 1);
    },
    changedSelectType(index) {
      this.ticketFormLocal.ticketUserProfile.profileItems[index].values = [];
    },
    next() {
      this.ticketFormLocal.price = parseFloat(this.ticketFormLocal.price);
      if (this.ticketFormLocal.roles.indexOf(this.ticketFormLocal.type) < 0) {
        this.ticketFormLocal.roles = [...this.ticketFormLocal.roles, this.ticketFormLocal.type];
      }

      this.manageConferenceFeature();
      this.manageDiscussionGroupsFeature();
      this.manageContentCategoriesFeature();

      this.ticketFormLocal.options.generalAssemblies = this.gaAccess.map((it) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { name, ...rest } = it;

        return { ...rest };
      });

      this.$emit('next-step');
    },
    selectAllConferences() {
      this.$nextTick(() => {
        if (this.selectedAllConferences) {
          this.selectedConferences = [];
        } else {
          this.selectedConferences = [...this.conferences.map((i) => i.id)];
        }
      });
    },
    selectAllDiscussionGroups() {
      this.$nextTick(() => {
        if (this.selectedAllDiscussionGroups) {
          this.selectedDiscussionGroups = [];
        } else {
          this.selectedDiscussionGroups = [...this.discussionGroups.map((i) => i.id)];
        }
      });
    },
    selectAllContentCategories() {
      this.$nextTick(() => {
        if (this.selectedAllContentCategories) {
          this.selectedContentCategories = [];
        } else {
          this.selectedContentCategories = [...this.contentCategories.map((i) => i.id)];
        }
      });
    },
    manageConferenceFeature() {
      if (this.selectedConferences.length === 0) {
        this.ticketFormLocal.options.features.conferences = false;
      }

      if (this.conferences.length !== this.selectedConferences.length) {
        this.ticketFormLocal.options.conferences = [
          ...this.selectedConferences.map((item) => item?.id || item),
        ];
      } else {
        this.ticketFormLocal.options.conferences = [];
      }
    },
    manageDiscussionGroupsFeature() {
      if (this.selectedDiscussionGroups.length === 0) {
        this.ticketFormLocal.options.features.discussionGroups = false;
      }

      if (this.discussionGroups.length !== this.selectedDiscussionGroups.length) {
        this.ticketFormLocal.options.discussionGroups = [
          ...this.selectedDiscussionGroups.map((item) => item?.id || item),
        ];
      } else {
        this.ticketFormLocal.options.discussionGroups = [];
      }
    },
    manageContentCategoriesFeature() {
      if (this.selectedContentCategories.length === 0) {
        this.ticketFormLocal.options.features.contentLibrary = false;
      }

      if (this.contentCategories.length !== this.selectedContentCategories.length) {
        this.ticketFormLocal.options.contentCategories = [
          ...this.selectedContentCategories.map((item) => item?.id || item),
        ];
      } else {
        this.ticketFormLocal.options.contentCategories = [];
      }
    },
    gaAccessChange(options) {
      if (!this.ticketFormLocal.options.features.generalAssemblies) {
        return;
      }

      this.gaAccess = this.gaAccess.map((it) => {
        if (it.id !== options.id) {
          return it;
        }

        const canAccess = !options.canAccess;
        return {
          ...it,
          canAccess,
          canVote: !canAccess ? false : options.canVote,
        };
      });
    },
    async initializeConferences(eventId) {
      await this[GET_CONFERENCES_FOR_EVENT](eventId);
      if (this?.conferences?.length > 0 && this.selectedTicket?.options?.features?.conferences) {
        if (this.selectedTicket?.options?.conferences?.length > 0) {
          this.selectedConferences = [
            ...this.conferences.filter((item) =>
              this.selectedTicket.options.conferences.includes(item.id),
            ),
          ];
        } else {
          this.selectedConferences = [...this.conferences];
        }
      }
    },
    async initializeDiscussionGroups(eventId) {
      await this[GET_DISCUSSION_GROUPS](eventId);

      if (
        this?.discussionGroups?.length > 0 &&
        this.selectedTicket?.options?.features?.discussionGroups
      ) {
        if (this.selectedTicket?.options?.discussionGroups?.length > 0) {
          this.selectedDiscussionGroups = [
            ...this.discussionGroups.filter((item) =>
              this.selectedTicket.options.discussionGroups.includes(item.id),
            ),
          ];
        } else {
          this.selectedDiscussionGroups = [...this.discussionGroups];
        }
      }
    },
    async initializeContentCategories(eventId) {
      await this[GET_CONTENT_CATEGORIES](eventId);
      if (this?.contentCategories?.length > 0) {
        if (this.selectedTicket?.options?.contentCategories?.length > 0) {
          this.selectedContentCategories = [
            ...this.contentCategories.filter((item) =>
              this.selectedTicket.options.contentCategories.includes(item.id),
            ),
          ];
        } else {
          this.selectedContentCategories = [...this.contentCategories];
        }
      }
    },
  },
  async mounted() {
    await this.initializeConferences(this.eventId);
    await this.initializeDiscussionGroups(this.eventId);
    await this.initializeContentCategories(this.eventId);
    this.gaAccess = toTicketGAOptionsWithName(this.selectedTicket, this.generalAssemblies);
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

#editTicketForm {
  min-height: calc(100vh - 6rem);
}

.editTicketAdmin {
  &__label {
    font-weight: $semi-bold;
  }

  &__restricted {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      &:first-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

.subtitle {
  margin-bottom: 10px;
}

::v-deep .v-text-field {
  padding-top: 0;

  .v-label {
    font-weight: $semi-bold;
  }
}
</style>
