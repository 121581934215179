<template>
  <v-container class="ga">
    <h2 class="ga__title">{{ currentEvent.name }} - {{ $t('admin.generalAssemblies.title') }}</h2>

    <div class="ga__actions">
      <v-btn
        large
        depressed
        class="actions__create"
        color="primary"
        :disabled="!canCreate"
        @click="openDialog('edit')"
      >
        {{ $t('conference.list.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <base-list
      backgroundColor="var(--v-adminBackground-base)"
      :loading="assembliesAreLoading"
      :headerProps="{ sortIcon: null }"
      :headers="headers"
      :search="search"
      :items="generalAssemblies"
      :itemsPerPage="10"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span>{{ item.name }}</span>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span>{{ item.description }}</span>
      </template>

      <template v-slot:[`item.agenda`]="{ item }">
        <span>{{ item.agenda }}</span>
      </template>

      <template v-slot:[`item.startTime`]="{ item }">
        <span>{{ $d(Date.parse(item.startTime), 'long') }}</span>
      </template>

      <template v-slot:[`item.endTime`]="{ item }">
        <span>{{ $d(Date.parse(item.endTime), 'long') }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="currentLoggedInUser"
          @delete-item="openDialog('delete', item)"
          @edit-item="openDialog('edit', item)"
          :can-edit="canEdit"
          :can-delete="canDelete"
          :is-disabled="hasQuestions(item)"
          :disabled-tooltip="$t('general-assembly.delete.hasContent')"
        />
      </template>
    </base-list>

    <v-dialog
      content-class="v-dialog--overlay"
      v-model="dialog.edit"
      v-if="dialog.edit"
      width="800"
      @click:outside="closeDialog('edit')"
    >
      <v-card>
        <v-card-title> {{ currentEvent.name }} - {{ isEdit }} </v-card-title>

        <v-card-text
          ><edit-general-assemblies-admin
            :eventId="eventId"
            :assemblyId="selectedGeneralAssemblyId"
            :editing="!!this.selectedGeneralAssembly"
            @closeDialog="closeDialog('edit')"
            @save="loadGeneralAllAssemblies"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <dialog-confirmation
      :v-if="dialog.delete"
      :visible="dialog.delete"
      :title="$t('general-assembly.delete.deleteConfirmationDialog.title')"
      :content="$t('general-assembly.delete.deleteConfirmationDialog.content')"
      @cancel="closeDialog('delete')"
      @confirm="confirmationDialogConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DialogConfirmation from '@/views/DialogConfirmation.vue';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { ADMIN_ASSEMBLY_MODULE } from '@/stores/umanize-admin/actions/general-assembly/general-assembly.actions';
import { GET_ALL_ASSEMBLIES } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { DELETE_GENERAL_ASSEMBLY } from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';

import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import EditGeneralAssembliesAdmin from './EditGeneralAssembliesAdmin.vue';

export default {
  name: 'GeneralAssembliesAdmin',
  components: {
    AdminTableActions,
    DialogConfirmation,
    BaseList,
    EditGeneralAssembliesAdmin,
    AppSearch,
  },
  data: () => ({
    dialog: {
      edit: false,
      delete: false,
    },
    search: '',
    selectedGeneralAssembly: null,
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, { currentEvent: 'event' }),
    ...mapGetters(ADMIN_ASSEMBLY_MODULE, ['generalAssemblies', 'assembliesAreLoading']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    headers() {
      return [
        {
          text: this.$t('admin.generalAssemblies.name'),
          value: 'name',
        },
        {
          text: this.$t('admin.generalAssemblies.description'),
          value: 'description',
          sortable: false,
        },
        {
          text: this.$t('admin.generalAssemblies.startTime'),
          value: 'startTime',
        },
        {
          text: this.$t('admin.generalAssemblies.endTime'),
          value: 'endTime',
        },
        {
          value: 'actions',
          width: '100px',
        },
      ];
    },
    canEdit() {
      return PermissionsUtil.isAuthorized(
        ['permission.generalAssemblies.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.generalAssemblies.canDelete'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.generalAssemblies.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    isEdit() {
      return this.selectedGeneralAssembly?.id
        ? this.$t('admin.generalAssemblies.titleEdit')
        : this.$t('admin.generalAssemblies.titleAdd');
    },
    selectedGeneralAssemblyId() {
      return this.selectedGeneralAssembly?.id;
    },
    eventId() {
      return this.$route.params.eventId;
    },
  },
  methods: {
    ...mapActions(ADMIN_ASSEMBLY_MODULE, [GET_ALL_ASSEMBLIES, DELETE_GENERAL_ASSEMBLY]),
    async loadGeneralAllAssemblies() {
      await this[GET_ALL_ASSEMBLIES](this.eventId);
    },
    onSearchChange(search) {
      this.search = search;
    },
    openDialog(name, item) {
      this.selectedGeneralAssembly = item;
      this.dialog[name] = true;
    },
    closeDialog(name) {
      this.selectedGeneralAssembly = null;
      this.dialog[name] = false;
    },
    async confirmationDialogConfirm() {
      await this.deleteGeneralAssembly(this.selectedGeneralAssembly.id);
      this.closeDialog('delete');
    },
    async deleteGeneralAssembly(id) {
      await this[DELETE_GENERAL_ASSEMBLY]({
        eventId: this.eventId,
        assemblyId: id,
      });
    },
    hasQuestions() {
      return this.selectedGeneralAssembly?.hasQuestions;
    },
  },
  async mounted() {
    await this.loadGeneralAllAssemblies();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.ga {
  padding: 50px;
  min-height: calc(100vh - 6rem);

  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
