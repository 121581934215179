<template>
  <v-container>
    <v-form :disabled="!canCreate" @submit.prevent="save">
      <h2 class="title">{{ $t('admin.custom-ui.buttons.title') }}</h2>

      <v-row v-if="eventHasExposition">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="customUiForm.event3dBtnText"
            :label="$t('admin.custom-ui.buttons.event3dBtnText')"
            :disabled="!canUpdate"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn x-large class="button" :style="colors">
            {{ buttonName('event3dBtnText') }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- Section for 2D Button -->
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="customUiForm.event2dBtnText"
            :label="$t('admin.custom-ui.buttons.event2dBtnText')"
            :disabled="!canUpdate"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn x-large class="button" :style="colors">
            {{ buttonName('event2dBtnText') }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- Other Button Sections (Conference, General Assembly, etc.) -->
      <v-row v-if="eventHasConference">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="customUiForm.conferenceBtnText"
            :label="$t('admin.custom-ui.buttons.conferenceBtnText')"
            :disabled="!canUpdate"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn x-large class="button" :style="colors">
            {{ buttonName('conferenceBtnText') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="eventHasGeneralAssembly">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="customUiForm.generalAssembliesBtnText"
            :label="$t('admin.custom-ui.buttons.generalAssembliesBtnText')"
            :disabled="!canUpdate"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn x-large class="button" :style="colors">
            {{ buttonName('generalAssembliesBtnText') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="eventHasDiscussionGroups">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="customUiForm.discussionGroupsBtnText"
            :label="$t('admin.custom-ui.buttons.discussionGroupsBtnText')"
            :disabled="!canUpdate"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn x-large class="button" :style="colors">
            {{ buttonName('discussionGroupsBtnText') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="eventHasContentLibrary">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="customUiForm.contentLibraryBtnText"
            :label="$t('admin.custom-ui.buttons.contentLibraryBtnText')"
            :disabled="!canUpdate"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn x-large class="button" :style="colors">
            {{ buttonName('contentLibraryBtnText') }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- Section for Colors -->
      <h2 class="title">{{ $t('admin.custom-ui.colors.title') }}</h2>

      <v-row>
        <v-col cols="12" sm="6">
          <p class="colors__title">{{ $t('admin.custom-ui.colors.btnColor') }}</p>
          <v-color-picker
            @update:color="setBtnColor"
            dot-size="10"
            canvas-height="50"
            hide-mode-switch
            mode="hexa"
            :disabled="!canUpdate"
            v-model="btnColor"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <p class="colors__title">{{ $t('admin.custom-ui.colors.btnTextColor') }}</p>
          <v-color-picker
            @update:color="setBtnTextColor"
            dot-size="10"
            canvas-height="50"
            hide-mode-switch
            mode="hexa"
            :disabled="!canUpdate"
            v-model="btnTextColor"
          />
        </v-col>
      </v-row>

      <v-card-actions class="actions justify-end">
        <v-btn color="primary" type="submit" :disabled="!canUpdate">
          {{ $t('globals.save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';

import { CustomUI } from '@/models/custom-ui/custom-ui.model';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADMIN_CUSTOM_UI_MODULE,
  CLEAR_CUSTOM_UI,
  SAVE_CUSTOM_UI,
} from '@/stores/umanize-admin/actions/custom-ui/admin-custom-ui.actions';
import { GET_CUSTOM_UI } from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';

export default {
  name: 'CustomUIGeneral',
  props: {
    canUpdate: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    btnColor: '#00B5A8',
    btnTextColor: '#FFFFFF',
    customUiForm: new CustomUI(),
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(ADMIN_CUSTOM_UI_MODULE, ['customUi']),
    placeholderValues() {
      return {
        event3dBtnText: this.$t('lobby.visit'),
        event2dBtnText: this.$t('lobby.exhibitors.action'),
        conferenceBtnText: this.$tc('lobby.conferences.title'),
        generalAssembliesBtnText: this.$t('lobby.generalAssemblies.title'),
        discussionGroupsBtnText: this.$tc('lobby.discussionGroups.title'),
        contentLibraryBtnText: this.$t('lobby.content'),
        btnColor: '#00B5A8',
        btnTextColor: '#FFFFFF',
      };
    },
    eventHasExposition() {
      return this.event.options.exposition;
    },
    eventHasConference() {
      return this.event.options.conferences;
    },
    eventHasGeneralAssembly() {
      return this.event.options.generalAssembly;
    },
    eventHasDiscussionGroups() {
      return this.event.options.discussionGroups;
    },
    eventHasContentLibrary() {
      return this.event.options.contentLibrary;
    },
    colors() {
      return `background-color: ${this.btnColor};color: ${this.btnTextColor}`;
    },
  },
  methods: {
    ...mapActions(ADMIN_CUSTOM_UI_MODULE, [GET_CUSTOM_UI, CLEAR_CUSTOM_UI, SAVE_CUSTOM_UI]),
    buttonName(btn) {
      return this.customUiForm[btn] || this.placeholderValues[btn];
    },
    setBtnColor(color) {
      this.btnColor = color.hex;
    },
    setBtnTextColor(color) {
      this.btnTextColor = color.hex;
    },
    async save() {
      const form = { ...this.customUiForm };
      form.btnColor = this.btnColor;
      form.btnTextColor = this.btnTextColor;

      await this[SAVE_CUSTOM_UI](form);
    },
  },
  async mounted() {
    const { eventId } = this.$route.params;
    await this[GET_CUSTOM_UI]({ eventId });
    this.customUiForm = {
      ...cloneDeep(this.customUi),
      eventId: this.$route.params.eventId,
    };

    this.btnColor = this.customUiForm.btnColor || this.placeholderValues.btnColor;
    this.btnTextColor = this.customUiForm.btnTextColor || this.placeholderValues.btnTextColor;
  },
  async beforeDestroy() {
    await this[CLEAR_CUSTOM_UI]();
  },
};
</script>
