<template>
  <div>
    <base-list
      class="mt-4"
      className="img-first"
      backgroundColor="var(--v-adminBackground-base)"
      v-model="dataSelectedConferences"
      :headers="headers"
      :items="conferences"
      :search="search"
      :loading="conferencesAreLoading"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.coverImage`]="{ item }">
        <div class="container-img">
          <img :src="item.coverImages[0]" class="container-img__img" v-if="item.coverImages" />
          <p class="container-img__placeholder" v-else>
            {{ $t('event.edit.noCover') }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ conferenceName(item.name) }}
      </template>

      <template v-slot:[`item.description`]="{ item }">
        {{ conferenceDescription(item.description) }}
      </template>

      <template v-slot:[`item.startTime`]="{ item }">
        {{ $d(Date.parse(item.startTime), 'long') }}
      </template>

      <template v-slot:[`item.endTime`]="{ item }">
        {{ $d(Date.parse(item.endTime), 'long') }}
      </template>

      <template v-slot:[`item.public`]="{ item }">
        <v-icon class="icon icon__restricted" v-if="!item.public"> mdi-lock-outline </v-icon>
        <v-icon class="icon" v-else>mdi-lock-open-outline</v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="user"
          @edit-item="editConference(item)"
          @delete-item="deleteConference(item)"
          :is-disabled="hasPartners(item)"
          :disabled-tooltip="$t('conference.delete.hasContent')"
          :can-edit="canEdit"
          :can-delete="canDelete"
        />
      </template>
    </base-list>
  </div>
</template>

<script>
import StringUtils from '@/helpers/string/string.helper';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';

const NAME_SIZE_LIMIT = 70;
const DESCRIPTION_SIZE_LIMIT = 70;

export default {
  name: 'ConferencesTable',
  components: { AdminTableActions, BaseList },
  props: {
    conferences: {
      type: Array,
      default: () => [],
    },
    conferencesWithPartners: {
      type: Array,
      default: () => [],
    },
    conferencesAreLoading: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selectedConferences: {
      type: Array,
      default: () => [],
    },
    withActions: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataSelectedConferences: {
      get() {
        return this.selectedConferences;
      },
      set(value) {
        this.$emit('changedSelectedConferences', value);
      },
    },
    headers() {
      return [
        {
          value: 'coverImage',
          width: '150px',
        },
        {
          text: this.$t('conference.editModal.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('conference.editModal.description'),
          value: 'description',
          sortable: false,
        },
        {
          text: this.$t('conference.editModal.startTime'),
          value: 'startTime',
        },
        {
          text: this.$t('conference.editModal.endTime'),
          value: 'endTime',
        },
        {
          text: this.$t('conference.editModal.public'),
          value: 'public',
          sortable: false,
          align: 'center',
          width: '75px',
        },
        {
          value: 'actions',
          width: '100px',
        },
      ];
    },
  },
  methods: {
    editConference(item) {
      this.$emit('edit-conference', item);
    },
    deleteConference(item) {
      this.$emit('delete-conference', item);
    },
    conferenceDescription(description) {
      return StringUtils.shorten(description, DESCRIPTION_SIZE_LIMIT);
    },
    conferenceName(name) {
      return StringUtils.shorten(name, NAME_SIZE_LIMIT);
    },
    hasPartners(item) {
      return this.conferencesWithPartners.includes(item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}

::v-deep .a-datatable.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 16px;
}

.container-img {
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__placeholder {
    max-width: 100px;
    text-align: center;
    margin: 0;
  }
}

@include breakpoint(medium) {
  .container-img {
    width: 150px;
    height: 125px;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--v-gray-base);

    &__img {
      border-radius: 10px 0 0 10px;
    }
  }
}
</style>
