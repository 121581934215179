import { CsvUser } from '@/models/user/csv-user.model';
import { User } from '@/models';

export default class FileUtil {
  public static convertUsersToCsvString(users: CsvUser[]): string {
    const csvUsers = users.map((user) => new CsvUser(user, false));
    const headersString = `${Object.keys(csvUsers[0]).join(',')}\n`;
    const linesString = csvUsers.map((csvUser) => Object.values(csvUser).join(',')).join('\n');

    return headersString + linesString;
  }

  public static convertUsersCsvToCsvUsers(result: string | ArrayBuffer): CsvUser[] {
    const lines = result.toString().split(/\r?\n/);
    const keys = lines
      .splice(0, 1)[0]
      .split(',')
      .map((item: string) => item.replace(/"/g, '').trim());
    return lines
      .map((item, index) => {
        const lineItems = item.split(',').map((item) => item.replace(/"/g, '').trim());
        const csvUserFromLine = new CsvUser(
          {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            updatePassword: false,
            sendNotification: true,
            index,
            deleted: false,
          },
          false,
        );

        lineItems.forEach((lineItem, index) => {
          csvUserFromLine[keys[index]] = lineItem;
        });

        return lineItems.length > 1 ? csvUserFromLine : null;
      })
      .filter((v) => !!v);
  }

  public static findErrors({
    fileUsers,
    eventUsers,
  }: {
    fileUsers: CsvUser[];
    eventUsers: User[];
  }) {
    const errors = [];
    let errorCount = 0;

    fileUsers.forEach((fileUser, index) => {
      errors[index] = {
        alreadyExists: false,
        duplicate: false,
        wrongTicket: false,
        badEmail: false,
        errorCount: 0,
      };

      if (!fileUser.deleted && this.duplicateUserFromDatabase({ fileUser, eventUsers })) {
        errors[index].alreadyExists = true;
        errors[index].errorCount += 1;
        errorCount += 1;
      }

      if (!fileUser.deleted && this.duplicateUserFromFile({ fileUser, fileUsers })) {
        errors[index].duplicate = true;
        errors[index].errorCount += 1;
        errorCount += 1;
      }

      if (!fileUser.deleted && this.badEmail({ fileUser })) {
        errors[index].badEmail = true;
        errors[index].errorCount += 1;
        errorCount += 1;
      }
    });

    return { errors, errorCount };
  }

  private static duplicateUserFromDatabase({ fileUser, eventUsers }): boolean {
    return !!eventUsers.find((eventUser) => eventUser.email === fileUser.email);
  }

  private static duplicateUserFromFile({ fileUsers, fileUser }): boolean {
    return fileUsers.filter((item) => item.email === fileUser.email).length > 1;
  }

  private static badEmail({ fileUser }): boolean {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return !re.test(String(fileUser.email).toLowerCase());
  }
}
