<template>
  <v-container>
    <v-form :disabled="!canCreate" @submit.prevent="save">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <p class="title">{{ $t('admin.custom-ui.footer.backgroundColor') }}</p>

            <v-color-picker
              @update:color="setBackgroundColor"
              dot-size="10"
              canvas-height="50"
              hide-mode-switch
              mode="hexa"
              :disabled="!canUpdate"
              v-model="footerBackgroundColor"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <p class="title">{{ $t('admin.custom-ui.footer.textColor') }}</p>

            <v-color-picker
              @update:color="setTextColor"
              dot-size="10"
              canvas-height="50"
              hide-mode-switch
              mode="hexa"
              :disabled="!canUpdate"
              v-model="footerTextColor"
            />
          </v-col>

          <v-col cols="12" sm="12">
            <p class="title">{{ $t('admin.custom-ui.footer.logoPosition') }}</p>

            <v-radio-group v-model="footerLogoPosition" row>
              <v-radio :label="$t('admin.custom-ui.footer.logoPositionLeft')" value="left" />

              <v-radio
                :label="$t('admin.custom-ui.footer.logoPositionTopCenter')"
                value="topCenter"
              />
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <p class="title">{{ $t('admin.custom-ui.footer.logoContentFr') }}</p>

            <vue-editor-image
              name="fr"
              validationRules=""
              :content="footerLogoContent.fr"
              :disabled="!canUpdate"
              @set-content="setContent"
              @set-has-error-content="setHasErrorContent"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <p class="title">{{ $t('admin.custom-ui.footer.logoContentEn') }}</p>

            <vue-editor-image
              name="en"
              validationRules=""
              :content="footerLogoContent.en"
              :disabled="!canUpdate"
              @set-content="setContent"
              @set-has-error-content="setHasErrorContent"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              :disabled="!canUpdate"
              :label="$t('admin.custom-ui.footer.copyrightFr')"
              :placeholder="$t('admin.custom-ui.footer.copyrightFrPlaceholder')"
              autocomplete
              color="primaryFont"
              hide-details="auto"
              persistent-placeholder
              required
              v-model="footerCopyright.fr"
              maxlength="125"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              :disabled="!canUpdate"
              :label="$t('admin.custom-ui.footer.copyrightEn')"
              :placeholder="$t('admin.custom-ui.footer.copyrightEnPlaceholder')"
              autocomplete
              color="primaryFont"
              hide-details="auto"
              persistent-placeholder
              required
              v-model="footerCopyright.en"
              maxlength="125"
            />
          </v-col>
        </v-row>

        <v-card-actions class="actions justify-end">
          <v-btn color="primary" type="submit" :disabled="!canUpdate">
            {{ $t('globals.save') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { CustomUI } from '@/models/custom-ui/custom-ui.model';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADMIN_CUSTOM_UI_MODULE,
  SAVE_CUSTOM_UI,
  CLEAR_CUSTOM_UI,
} from '@/stores/umanize-admin/actions/custom-ui/admin-custom-ui.actions';
import { GET_CUSTOM_UI } from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';
import VueEditorImage from '@/components/vue-editor-image/VueEditorImage.vue';

const defaultFooterBackgroundColor = '#272727';
const defaultFooterTextColor = '#808080';

export default {
  components: { VueEditorImage },
  name: 'CustomUIFooter',
  props: {
    canUpdate: {
      type: Boolean,
      default: () => false,
    },
    canCreate: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      footerBackgroundColor: defaultFooterBackgroundColor,
      footerTextColor: defaultFooterTextColor,
      customUiForm: new CustomUI(),
      footerCopyright: { fr: '', en: '' },
      footerLogoContent: { fr: '', en: '' },
      footerLogoPosition: 'left',
      validForm: {
        footerLogoContent: {
          fr: true,
          en: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(ADMIN_CUSTOM_UI_MODULE, ['customUi']),
    placeholderValues() {
      return {
        footerBackgroundColor: defaultFooterBackgroundColor,
        footerTextColor: defaultFooterTextColor,
      };
    },
    colors() {
      return `background-color: ${this.footerBackgroundColor}; color: ${this.footerTextColor}`;
    },
  },
  methods: {
    ...mapActions(ADMIN_CUSTOM_UI_MODULE, [GET_CUSTOM_UI, CLEAR_CUSTOM_UI, SAVE_CUSTOM_UI]),
    setBackgroundColor(color) {
      this.footerBackgroundColor = color.hex;
    },
    setTextColor(color) {
      this.footerTextColor = color.hex;
    },
    setContent(value, type) {
      this.footerLogoContent[type] = value;
    },
    setHasErrorContent(value, type) {
      this.validForm.footerLogoContent[type] = value;
    },
    async save() {
      const form = { ...this.customUiForm };
      form.footerBackgroundColor = this.footerBackgroundColor ?? this.defaultFooterBackgroundColor;
      form.footerTextColor = this.footerTextColor ?? this.defaultFooterTextColor;
      form.footerLogoContent = { fr: this.footerLogoContent.fr, en: this.footerLogoContent.en };
      form.footerCopyright = { fr: this.footerCopyright.fr, en: this.footerCopyright.en };
      form.footerLogoPosition = this.footerLogoPosition || 'left';

      await this[SAVE_CUSTOM_UI](form);
    },
  },
  async mounted() {
    const { eventId } = this.$route.params;
    await this[GET_CUSTOM_UI]({ eventId });
    this.customUiForm = { ...this.customUi, eventId };
    this.footerLogoPosition = this.customUiForm.footerLogoPosition || 'left';
    this.footerBackgroundColor =
      this.customUiForm.footerBackgroundColor || this.placeholderValues.footerBackgroundColor;
    this.footerTextColor =
      this.customUiForm.footerTextColor || this.placeholderValues.footerTextColor;
    this.footerCopyright.fr =
      this.customUiForm.footerCopyright?.fr || this.$t('footer.copyright', 'fr');
    this.footerCopyright.en =
      this.customUiForm.footerCopyright?.en || this.$t('footer.copyright', 'en');
    this.footerLogoContent.fr = this.customUiForm.footerLogoContent?.fr || '';
    this.footerLogoContent.en = this.customUiForm.footerLogoContent?.en || '';
  },
};
</script>
